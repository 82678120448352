// Updates induction progress indictator
export const updateProgress = level => {
  const $breadcrumbItems = document.querySelectorAll(
    '.js-onboardingBreadcrumbs li'
  );

  $breadcrumbItems.forEach(($item, index) => {
    $item.classList.remove('is-active');

    index < level
      ? $item.classList.add('is-completed')
      : $item.classList.remove('is-active');

    if (index === level) {
      $item.classList.add('is-active');
    }
  });
};
