import { createEl } from 'util/create-el';
import fullScreenIcon from 'images/icons/full-screen.svg';
import { svg } from 'util/svg';
import { enableFullScreen } from '../methods/enable-full-screen';

export const fullScreen = () => {
  const $button = createEl('button', {
    className: 'c-video__fullScreen',
    type: 'button',
  });

  const $icon = svg(fullScreenIcon, 'c-video__fullScreenIcon');

  $button.appendChild($icon);
  $button.addEventListener('click', enableFullScreen);

  return $button;
};
