import { createEl } from 'util/create-el';
import { timeStamp } from '../util/timestamp';
import { settings } from '../settings';
import { addAnswer } from './answer';
import { addBlankAnswer } from '../methods/add-blank-answer';
import { questionNameHandler } from '../methods/question-name-handler';
import { preventReturn } from '../methods/prevent-return';

/**
 * #ADD QUESTION
 * @param   {Object} question Question content, if undefined we produce a blank answer.
 * @param   {Object} answers  Answers content, if undefined no answers are generated.
 * @returns {Node}
 */
export const addQuestion = (question, answers) => {
  // Define the defaults here for a blank question.
  const blankQuestion = {
    id: `question_${timeStamp()}`,
    value: null,
  };

  // If no question is passed through, we set it the values for a blank question.
  if (!question) question = blankQuestion;

  const $quiz = settings.$quiz();
  const $addQuestion = document.querySelector('.js-addQuestion');
  const questionsLength = $quiz.querySelectorAll('fieldset').length;

  // Define a base name
  const baseName = `induction[quiz][${question.id}]`;

  const $questionInputName = createEl('input', {
    name: `${baseName}question[value]`,
    className: 'js-quizQuestionInput',
    type: 'hidden',
    value: question.value,
  });

  const $questionInputId = createEl('input', {
    name: `${baseName}question[id]`,
    type: 'hidden',
    value: question.id,
  });

  const $quizQuestionDiv = createEl('div', {
    contentEditable: true,
    className: 'js-quizQuestionDiv',
    innerText: question.value,
  });

  $quizQuestionDiv.setAttribute(
    'placeholder',
    `Insert question ${questionsLength + 1}…`
  );

  const $addAnswerButton = createEl('button', {
    type: 'button',
    className: 'c-button  c-button--circle  js-tooltip',
    textContent: '+',
  });

  $addAnswerButton.dataset.tooltip = 'Add an answer';

  const $addAnswerGridContainer = createEl('div', {
    className: 'grid-x  grid-margin-y  grid-margin-x',
  });

  const $addAnswerGridCell = createEl('div', {
    className: 'cell  auto',
  });

  const $fieldset = createEl('fieldset', {
    className: 'js-quizQuestion',
    id: question.id,
  });

  const $legend = document.createElement('legend');

  $fieldset.dataset.name = baseName;
  $fieldset.appendChild($legend);
  $fieldset.appendChild($addAnswerGridContainer);

  $addAnswerGridCell.appendChild($addAnswerButton);
  $addAnswerGridContainer.appendChild($addAnswerGridCell);

  $legend.appendChild($questionInputId);
  $legend.appendChild($questionInputName);
  $legend.appendChild($quizQuestionDiv);

  $quiz.insertBefore($fieldset, $addQuestion);

  if (!question.value) $quizQuestionDiv.focus();

  // Setup events
  $addAnswerButton.addEventListener('click', addBlankAnswer);
  $quizQuestionDiv.addEventListener('keyup', questionNameHandler);
  $quizQuestionDiv.addEventListener('keydown', preventReturn);

  // If there are answers, map through this array and create an answer for each
  if (answers) answers.map(answer => addAnswer(null, answer, $fieldset));
};
