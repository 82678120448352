import { TE } from 'util/throw-error';
import { settings } from '../settings';
import { createMarkup } from './create-markup';

export const fetchQuizData = async () => {
  // Return if there are already quiz questions output
  if (document.querySelector('.js-quizQuestion') !== null) return;

  const $quiz = settings.$quiz();
  const endpoint = $quiz.dataset.endpoint;

  try {
    const response = await fetch(endpoint);
    const json = await response.json();
    const questionSets = await json.quiz;

    if (questionSets !== null) {
      createMarkup(questionSets);

      const $induction = $quiz.closest('.js-induction');
      const questionsLength = Object.keys(questionSets).length;

      const eventData = {
        detail: {
          questionsLength,
          questionSets,
          $induction,
        },
      };

      const eventName = 'quiz:fetchData';
      const newEvent = new CustomEvent(eventName, eventData);
      $quiz.dispatchEvent(newEvent);
    }
  } catch (error) {
    TE(error);
  }
};
