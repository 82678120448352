import { updateProgress } from './update-progress';
import { hideUI } from './hide-ui';

export const playPauseHandler = event => {
  const $video = event.currentTarget;
  const $videoContainer = $video.closest('.js-video');

  $videoContainer.classList.toggle('is-playing');
  $videoContainer.classList.remove('has-ended', 'has-hiddenUI');

  const $progress = $videoContainer.querySelector('progress');

  if (!$video.playing) {
    $videoContainer.currentTimeInterval = setInterval(
      updateProgress,
      200,
      $video,
      $progress
    );
    clearTimeout($videoContainer.hideUITimeout);
    $videoContainer.hideUITimeout = setTimeout(hideUI, 3000, $video);
  }

  // Toggle the playing state
  $video.playing = !$video.playing;
};
