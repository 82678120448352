import { settings } from '../settings';

export const showQuestionSet = $questionSet => {
  const $quiz = settings.$quiz();
  const $questionSets = $quiz.querySelector('.js-quizQuestionSets');

  $questionSets.style.height = `${$questionSet.offsetHeight}px`;
  $questionSet.classList.add('is-active');

  // Enable radios
  const $radios = $questionSet.querySelectorAll('input[type="radio"]');
  $radios.forEach($radio => {
    $radio.disabled = false;
  });
};
