/*----------------------------------------------------------------------------*\
  #TEXTAREA
\*----------------------------------------------------------------------------*/

const Textareas = () => {
  // Private: Default settings object
  const _settings = {
    textareaSelector: '.js-textarea',
  };

  const $textareas = () =>
    document.querySelectorAll(_settings.textareaSelector);

  // Private: Change textarea
  const _handleChange = $textarea => {
    if ($textarea.target) $textarea = $textarea.target;

    $textarea.style.height = 'inherit';
    $textarea.style.height = _scrollHeightPlusBorders($textarea);
  };

  // Private: Get height and border-width from element (textarea)
  const _scrollHeightPlusBorders = $textarea => {
    if ($textarea.target) $textarea = $textarea.target;

    return (
      $textarea.scrollHeight +
      getComputedStyle($textarea)
        .getPropertyValue('border-width')
        .replace('px', '') *
        2 +
      'px'
    );
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!$textareas()) return;
    // Remove any event listeners
    $textareas().forEach($textareaInstance => {
      $textareaInstance.removeEventListener('input', _handleChange);
    });
  };

  const _setupEventListeners = () => {
    $textareas().forEach($textareaInstance => {
      $textareaInstance.addEventListener('input', _handleChange);
    });
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!$textareas()) return;
    _setupEventListeners();

    $textareas().forEach($textareaInstance => {
      _handleChange($textareaInstance);
    });
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
};

// Default module export
export default Textareas;
