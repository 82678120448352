import { createEl } from 'util/create-el';
import { answerHandler } from '../methods/answer-handler';

export const addAnswers = ($targetNode, answers) => {
  const $answers = createEl('ol', {
    className: 'c-quiz__answers  js-quizAnswers',
  });

  answers.map(answer => {
    const $answer = createEl('li', {
      className: 'c-quiz__answer  js-answer',
      correct: answer.correct,
    });

    const $radio = createEl('input', {
      className: 'c-quiz__answerRadio',
      type: 'radio',
      name: `[${$targetNode.id}][answers]`,
      id: answer.id,
      disabled: true,
    });

    const $label = createEl('label', {
      className: 'c-quiz__answerLabel',
      textContent: answer.value,
      htmlFor: answer.id,
    });

    $answer.appendChild($radio);
    $answer.appendChild($label);
    $answers.appendChild($answer);

    $radio.addEventListener('change', answerHandler);
  });

  $targetNode.appendChild($answers);
};
