import { createEl } from 'util/create-el';
import { progress } from './progress';
import { fullScreen } from './full-screen';
import { timeStamp } from './time-stamp';

export const addControls = $video => {
  const $timeline = progress();
  const $fullScreen = fullScreen();
  const $timeStamp = timeStamp();

  const $controls = createEl('div', {
    className: 'c-video__controls',
  });

  $controls.appendChild($timeline);
  $controls.appendChild($timeStamp);
  $controls.appendChild($fullScreen);
  $video.appendChild($controls);
};
