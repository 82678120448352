import { getPlaybackPosition } from '../util/get-playback-position';
import { showTooltip } from 'modules/application/tooltips';

export const displayPlaybackPosition = event => {
  const $progress = event.currentTarget;
  const position = getPlaybackPosition($progress);
  const $video = $progress.closest('.js-video').querySelector('video');
  const $timeStamp = $video.querySelector('.c-video__timeStamp');

  if (!$timeStamp) return;

  const rect = $progress.getBoundingClientRect();
  const posTop = rect.top + rect.height / 2 + 'px';
  const posLeft = event.clientX + 'px';

  let formattedTime = new Date(null);
  formattedTime.setSeconds(position.timeSeconds);
  formattedTime = formattedTime.toISOString().substr(14, 5);

  $timeStamp.textContent = formattedTime;
  $timeStamp.style.position = 'fixed';
  $timeStamp.style.top = posTop;
  $timeStamp.style.left = posLeft;

  showTooltip(null, $video);
};
