import { settings } from '../settings';
import { continueButtonHandler } from './continue-button-handler';
import { quizConfirmation } from './quiz-confirmation';

export const correctAnswerHandler = ($answer, $questionSet) => {
  const $quiz = settings.$quiz();
  const $progress = $questionSet.querySelector('.js-quizProgress');
  const $continueButton = $quiz.querySelector('.js-quizContinue');

  $progress.textContent = 'Correct answer!';
  $questionSet.classList.add('is-correct');

  const $last = [
    ...$quiz.querySelectorAll('.js-quizQuestionSet.has-choice'),
  ].pop();
  const $next = $last.nextElementSibling;

  if ($next) {
    $continueButton.disabled = false;
    $continueButton.textContent = 'Continue';
    $continueButton.addEventListener('click', continueButtonHandler);
    return;
  }

  quizConfirmation();
};
