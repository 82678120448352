/*----------------------------------------------------------------------------*\
  #OVERLAY
\*----------------------------------------------------------------------------*/

// Private: Default settings object
const _settings = {
  openClass: 'is-active',
};

// Public: Overlay object
export const $overlay = () => document.querySelector('.js-overlay');

// Public: Close overlay
export const closeOverlay = () => {
  $overlay().classList.remove(_settings.openClass);
};

// Public: Open overlay
export const openOverlay = () => {
  $overlay().classList.add(_settings.openClass);
};

// Private: Open/close state callback
const _stateCallback = event => {
  const open = $overlay().classList.contains(_settings.openClass);

  const eventData = {
    detail: { open },
  };

  const eventName = 'overlay:' + event.type;
  const newEvent = new CustomEvent(eventName, eventData);
  $overlay().dispatchEvent(newEvent);
};

const Overlay = () => {
  // Public: Destroy module instance
  const destroy = () => {
    if (!$overlay()) return;
    $overlay().removeEventListener('transitionstart', _stateCallback);
    $overlay().removeEventListener('transitionend', _stateCallback);
    $overlay().removeEventListener('click', closeOverlay);
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!$overlay()) return;
    $overlay().addEventListener('transitionstart', _stateCallback);
    $overlay().addEventListener('transitionend', _stateCallback);
    $overlay().addEventListener('click', closeOverlay);
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
};

// Default module export
export default Overlay;
