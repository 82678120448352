/*----------------------------------------------------------------------------*\
  #COPY EMERGENCY DETAILS
\*----------------------------------------------------------------------------*/

// Default module export
export default function(options) {
  // Private: Default settings object
  const _defaults = {
    selector: '.js-emergency-copy',
    $emergencyCopyCheckbox() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$emergencyCopyCheckbox().length > 0;
    },
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Private: Copy inputs if checkbox checked
  const _updateEmergencyDetails = event => {
    const $kinName = document.querySelector('.js-kin-name');
    const $kinNumber = document.querySelector('.js-kin-number');
    const $emergencyName = document.querySelector('.js-emergency-name');
    const $emergencyNumber = document.querySelector('.js-emergency-number');

    const _checked = event.target.checked;

    if (_checked) {
      $emergencyName.value = $kinName.value;
      $emergencyNumber.value = $kinNumber.value;
    }
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;
    const $checkbox = document.querySelector('.js-emergency-copy');
    $checkbox.addEventListener('change', _updateEmergencyDetails);
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
}
