/**
 * #CREATE ELEMENT
 *
 * @param   {String} elementName      The HTML element to create.
 * @param   {Object} [attributes={}]  Set of attributes to attach to the element.
 * @returns {Node}
 */
export const createEl = (elementName, attributes = {}) => {
  const $el = document.createElement(elementName);

  for (const key of Object.keys(attributes)) {
    $el[key] = attributes[key];
  }

  return $el;
};
