import { createEl } from 'util/create-el';
import { addAnswers } from './answers';
import { showQuestionSet } from '../methods/show-question-set';

export const addQuestion = (
  $targetNode,
  question,
  answers,
  correctAnswer,
  meta = {}
) => {
  const $header = createEl('header', {
    className: 'c-induction__panel  js-quizQuestionSetHeader',
  });

  // Grid
  const $headerGridContainer = createEl('div', {
    className: 'grid-x  grid-margin-y  grid-margin-x',
  });
  const $headerGridCellAuto = createEl('div', { className: 'cell  auto' });
  const $headerGridCellShrink = createEl('div', { className: 'cell  shrink' });

  const $question = createEl('h3', {
    textContent: question.value,
  });

  const $progress = createEl('span', {
    className: 'c-quiz__progress  js-quizProgress',
    textContent: `Question ${meta.questionNumber} of ${meta.questionsLength}`,
  });

  const $questionSet = createEl('li', {
    id: question.id,
    className: 'c-quiz__questionSet  js-quizQuestionSet',
    correctAnswer: correctAnswer,
  });

  $headerGridCellAuto.appendChild($question);
  $headerGridCellShrink.appendChild($progress);
  $headerGridContainer.appendChild($headerGridCellAuto);
  $headerGridContainer.appendChild($headerGridCellShrink);
  $header.appendChild($headerGridContainer);

  $questionSet.appendChild($header);

  addAnswers($questionSet, answers);

  $targetNode.appendChild($questionSet);

  if (meta.questionNumber === 1) {
    showQuestionSet($questionSet);
  }
};
