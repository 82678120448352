import { settings } from '../settings';
import { reinit } from 'util/methods';
import { TE } from 'util/throw-error';
import { addQuestion } from '../components/question';

export const fetchExistingQuiz = async () => {
  // Return if there are already quiz questions output
  if (document.querySelector('.js-quizQuestion') !== null) return;

  const $quiz = settings.$quiz();
  const inductionId = $quiz.dataset.inductionId;

  try {
    const response = await fetch(`/inductions/${inductionId}.json`);
    const json = await response.json();
    const questionSets = await json.quiz;

    if (questionSets !== null)
      Object.keys(questionSets).forEach(key => {
        const questionSet = questionSets[key];
        addQuestion(questionSet.question, questionSet.answers);
      });

    reinit('application', 'tooltips');
  } catch (error) {
    TE(error);
  }
};
