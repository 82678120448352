import { settings } from '../settings';
import { deleteItem } from '../util/delete-item';

export const deleteQuestion = event => {
  const $quiz = settings.$quiz();
  const $target = event.currentTarget;
  const $question = $target.closest('.js-quizQuestion');
  const $validationError = $quiz.querySelector(`#${$question.id}_dialog`);

  deleteItem(event, $question, 'answer');
  if ($validationError) $validationError.remove();
};
