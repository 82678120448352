import { TE } from './throw-error';

/**
 * #INIT, REINIT & DESTROY METHODS
 *
 * These methods hook into the private method `_triggerEvent`, to dispatch an event.
 * Our bundle has an event listener for this event, and knows to run the init, reinit or
 * destroy method accordingly.
 *
 * Instead of running these methods on the entirety of the application, it is also possible
 * to specify an individual module to perform this on.
 *
 * For example, to reinit the tooltips module:
 * reinit('application', 'tooltips');
 *
 * @param   {String} [context='application']  Context to trigger the method on (used for each bundle).
 * @param   {String} [module='undefined']     The specific module to run the method on.
 * @access  public
 */

// Public: Init
export const init = (context = 'application', module = 'undefined') => {
  _triggerEvent('init', context, module);
};

// Public: Reinit
export const reinit = (context = 'application', module = 'undefined') => {
  _triggerEvent('reinit', context, module);
};

// Public: Destroy
export const destroy = (context = 'application', module = 'undefined') => {
  _triggerEvent('destroy', context, module);
};

/**
 * #METHOD HANDLER
 * Executed for every init, reinit & destroy call.
 *
 * @param   {String}  action              Either init, reinit or destroy.
 * @param   {String}  [module=undefined]  Module instance - used if we are only running this for an individual module.
 * @param   {Object}  modules             Object containing all of our modules.
 * @access  public
 */
export const methodHandler = (action, module = undefined, modules) => {
  if (!action) TE('No action has been defined.');
  if (typeof action !== 'string')
    TE(
      `Invalid action used. Expected to receive a String, but got a ${typeof action} instead.`
    );

  if (module) {
    if (!modules)
      TE(
        'No modules have been defined. This Object is required in order to run the action against the existing module instance.'
      );
    if (typeof module !== 'string')
      TE(
        `Invalid module used. Expected to receive a String, but got a ${typeof module} instead.`
      );

    // Run the action for this specific module.
    _runMethod(action, module, modules);
  } else {
    if (!modules)
      TE(
        'No modules have been defined. Without this being defined we cannot run the action against anything.'
      );
    if (typeof modules !== 'object')
      TE(
        `Invalid modules used. Expected to receive an Object, but got a ${typeof modules} instead.`
      );

    // Iterate through each module & run the action.
    Object.keys(modules).forEach(module => {
      _runMethod(action, module, modules);
    });
  }
};

/**
 * #TRIGGER EVENT
 *
 * @param   {String} action             Either init, reinit or destroy.
 * @param   {String} context            Context to trigger the method on (used for each bundle).
 * @param   {String} [module=undefined] Optionally provide the specific module to run the action on.
 * @access  private
 */
const _triggerEvent = (action, context, module = undefined) => {
  const eventData = {
    detail: { module },
  };

  const event = new CustomEvent(`${context}:${action}`, eventData);
  document.dispatchEvent(event);
};

/**
 * #HAS METHOD
 * Determine if a module has a method or not.
 *
 * @param   {String}    action  Either init, reinit or destroy.
 * @param   {Function}  mod     Module instance.
 * @returns {Boolean}
 * @access  private
 */
const _hasMethod = (action, mod) => typeof mod[action] === 'function';

/**
 * #RUN METHOD
 * Run the specified action on the requested module.
 *
 * @param   {String}    action  Either init, reinit or destroy.
 * @param   {String}    module  Module instance.
 * @param   {Object}    modules Object containing all of our modules.
 * @access  private
 */
const _runMethod = (action, module, modules) => {
  // Find the specific module from within our modules Object.
  const mod = modules[module];
  if (!mod)
    TE('Unable to find this specific module within our modules object.');

  // If the action exists on the module, then execute it.
  if (_hasMethod(action, mod)) mod[action]();
};

/**
 * #GET MODULE
 * Retrieve a specific module.
 *
 * @param   {Event} event
 * @returns {String | undefined} Either returns a string with the module name, or undefined.
 * @access  public
 */
export const getModule = event => {
  return event['detail'] ? event.detail.module : undefined;
};
