import { settings } from '../settings';
import { showQuestionSet } from './show-question-set';
import { createNotice } from 'modules/shared/notice';

export const continueButtonHandler = () => {
  const $quiz = settings.$quiz();
  const $questionSets = $quiz.querySelector('.js-quizQuestionSets');
  const $questionSetsList = $quiz.querySelectorAll('.js-quizQuestionSet');
  const $continueButton = $quiz.querySelector('.js-quizContinue');
  const $quizNav = $quiz.querySelector('.js-quizNav');
  const $form = $quiz.closest('form');

  const $last = [
    ...$quiz.querySelectorAll('.js-quizQuestionSet.has-choice'),
  ].pop();
  const $next = $last.nextElementSibling;

  $questionSetsList.forEach($questionSet => {
    $questionSet.classList.remove('is-active');
  });

  $continueButton.disabled = true;
  $continueButton.removeEventListener('click', continueButtonHandler);

  if ($next) {
    showQuestionSet($next);
    return;
  }

  $questionSets.remove();
  $continueButton.parentNode.remove();
  $quizNav.remove();

  createNotice('Congratulations - the quiz has been successfully completed.');
};
