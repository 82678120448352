/*----------------------------------------------------------------------------*\
  #DOCUMENT UPLOADS

  This module has been forked from 'uploads.js' as new functionality requested
  has made the module too opinionated to be used globally without adding
  significant complexity.
\*----------------------------------------------------------------------------*/

// Default module export
export default function(options) {
  // Private: Default settings object
  const _defaults = {
    selector: '.js-documentUpload',
    $selectors() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$selectors().length > 0;
    },
    hoverClass: 'is-dragHover',
    uploadField: '.js-documentUploadField',
    uploadedFilesList: '.js-documentUploadFilesList div',
    uploadedFilesCache: '.js-documentUploadCachedFiles',
    filesNameSelector: '.js-documentUploadName',
    filesNameInputSelector: '.js-documentUploadNameInput',
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Private: Hover state
  const _hoverState = (event, $target = event.currentTarget) => {
    $target.closest(_settings.selector).classList.add(_settings.hoverClass);
  };

  // Private: Blur state
  const _blurState = (event, $target = event.currentTarget) => {
    $target.closest(_settings.selector).classList.remove(_settings.hoverClass);
  };

  // Private: Decide whether or not to toggle the Name Field visibility
  const _displayFileNameInput = (
    event,
    $target = event.currentTarget,
    $cache
  ) => {
    const $docUpload = $target.closest(_settings.selector);
    const $inputContainer = $docUpload.querySelector(
      _settings.filesNameSelector
    );
    const $input = $inputContainer.querySelector(
      _settings.filesNameInputSelector
    );

    // If cache is a thing, but it's got nothing in it
    if ($cache && !$cache.value > 0) return;

    $inputContainer.classList.remove('u-hide');
    $input.required = true;
  };

  const _validFile = (file, fileInput) => {
    if (fileInput.accept.length === 0) {
      return true;
    }

    const filename = file.name;
    const validExtensions = fileInput.accept.toLowerCase().split(',');
    const extension = filename.substr(filename.lastIndexOf('.')).toLowerCase();

    return validExtensions.indexOf(extension) >= 0;
  };

  // Private: Report files attached to input
  const _reportFiles = (
    event,
    $target = event.currentTarget,
    files = event.currentTarget.files
  ) => {
    const file = files[0];
    if (!file) return;

    console.log('_reportFiles has ran');

    // parent documentUpload element for finding child elements
    const $docUpload = $target.closest(_settings.selector);
    // Files list
    const $filesList = $docUpload.querySelector(_settings.uploadedFilesList);
    // File Name input
    const $nameInput = $docUpload.querySelector(
      _settings.filesNameInputSelector
    );

    if (files.length === 0) {
      $nameInput.required = false;
      return;
    }

    $filesList.innerHTML = ''; // Clear out the existing list before adding items.

    if (!_validFile(file, $target)) {
      $target.value = ''; // Invalid file so clear the input
      return;
    }

    // If there are files, output an item for each of them found.
    [...files].map(file => {
      // Create the item
      let $item = document.createElement('li');
      $item.className = 'c-uploadFiles__item';
      $item.innerText = file.name;
      // Add the item to filesList
      $filesList.appendChild($item);
    });

    //  Toggle the File Name visibility
    // Pass null for 3rd arg because we want to toggle the name input and don't
    // care about cached files at this point.
    _displayFileNameInput(null, $nameInput, null);
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($docUpload => {
      const $uploadField = $docUpload.querySelector(_settings.uploadField);
      const $input = $docUpload.querySelector('input[type="file"]');

      // Remove Event Listeners
      $uploadField.removeEventListener('dragenter', _hoverState);
      $uploadField.removeEventListener('dragleave', _blurState);
      $uploadField.removeEventListener('drop', _blurState);
      $input.removeEventListener('change', _reportFiles);
    });
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($docUpload => {
      const $uploadField = $docUpload.querySelector(_settings.uploadField);
      const $cached = $docUpload.querySelector(_settings.uploadedFilesCache);
      const $input = $docUpload.querySelector('input[type="file"]');

      // Set up Event Listeners
      $uploadField.addEventListener('dragenter', _hoverState);
      $uploadField.addEventListener('dragleave', _blurState);
      $uploadField.addEventListener('drop', _blurState);
      $input && $input.addEventListener('change', _reportFiles);

      // Run methods to show uploaded files & name input if required
      _reportFiles(false, $input, $input.files);
      $cached && _displayFileNameInput(null, $input, $cached);
    });
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
}
