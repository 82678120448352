import { settings } from '../settings';
import { addBlankQuestion } from '../methods/add-blank-question';
import { createEl } from 'util/create-el';

export const addQuestionButton = () => {
  const $addQuestionButton = createEl('button', {
    type: 'button',
    className: 'c-button  c-button--secondary  c-button--small  js-addQuestion',
    textContent: 'Add a new question',
  });

  $addQuestionButton.addEventListener('click', addBlankQuestion);
  settings.$quiz().appendChild($addQuestionButton);
};
