/*----------------------------------------------------------------------------*\
  #REJECTION
\*----------------------------------------------------------------------------*/

const RejectInduction = () => {
  // Private: Default settings object
  const _settings = {
    selectors: {
      wrapper: '.js-rejection',
      input: '.js-rejection-input',
      rejectButton: '.js-rejection-button',
      cancelButton: '.js-cancel-rejection',
    },
    openClass: 'is-open',
  };

  const $rejectWrapper = () => {
    return document.querySelector(_settings.selectors.wrapper);
  };

  const $input = () => {
    return document.querySelector(_settings.selectors.input);
  };

  const $rejectButton = () => {
    return document.querySelector(_settings.selectors.rejectButton);
  };

  const $cancelButton = () => {
    return document.querySelector(_settings.selectors.cancelButton);
  };

  const _openForm = e => {
    e.preventDefault();

    $rejectWrapper().classList.add(_settings.openClass);
    $input().setAttribute('required', true);
    $rejectWrapper().scrollIntoView(false);
  };

  const _cancelRejection = e => {
    e.preventDefault();

    $rejectWrapper().classList.remove(_settings.openClass);
    $input().value = '';
    $input().removeAttribute('required');
  };

  const _setupEventListeners = () => {
    $rejectButton().addEventListener('click', _openForm);
    $cancelButton().addEventListener('click', _cancelRejection);
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!$rejectWrapper()) return;

    $rejectButton().removeEventListener('click', _openForm);
    $cancelButton().removeEventListener('click', _cancelRejection);
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!$rejectWrapper()) return;

    // setup event listeners
    _setupEventListeners();
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
};

// Default module export
export default RejectInduction;
