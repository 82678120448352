export const updateQuestionSetsHeight = () => {
  const $questionSets = document.querySelector('.js-quizQuestionSets');
  if (!$questionSets) return;

  const $activeQuestionSet = $questionSets.querySelector(
    '.js-quizQuestionSet.is-active'
  );

  $questionSets.style.height = `${$activeQuestionSet.offsetHeight}px`;
};
