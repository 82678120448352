/*----------------------------------------------------------------------------*\
  # QR Codes
\*----------------------------------------------------------------------------*/

// Imports
import { TE } from 'util/throw-error';

// Default module export
export default function(options) {
  // Private: Default settings object
  const _defaults = {
    refreshContainer: '.js-qr-entry-panel',
    refreshTrigger: '.js-regenerate-qr',
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Private: Toggle dropdown method
  const _refreshQRPanel = event => {
    const container = document.querySelector(_settings.refreshContainer);
    if (!container)
      TE(`No element matching ${_settings.refreshContainer} could be found.`);
    container.innerHTML = event.detail[0];

    const flash = document.querySelector('.js-flash');
    flash.querySelector('p').innerHTML = 'QR successfully regenerated';
    flash.classList.add('is-open');
  };

  // Public: Destroy module instance
  const destroy = () => {
    const $buttons = document.querySelectorAll(_settings.refreshTrigger);
    $buttons.forEach($button =>
      $button.removeEventListener('ajax:success', _refreshQRPanel)
    );
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    const $buttons = document.querySelectorAll(_settings.refreshTrigger);
    $buttons.forEach($button =>
      $button.addEventListener('ajax:success', _refreshQRPanel)
    );
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
}
