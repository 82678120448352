import { createEl } from 'util/create-el';
import { createNav } from '../components/nav';
import { addQuestion } from '../components/question';
import { settings } from '../settings';

export const createMarkup = questionSets => {
  const $quiz = settings.$quiz();
  $quiz.classList.add('c-quiz');

  const $questionSets = createEl('ol', {
    className: 'c-quiz__questionSets  js-quizQuestionSets',
  });

  $quiz.appendChild($questionSets);
  createNav($quiz);

  Object.keys(questionSets).forEach((key, index) => {
    const questionSet = questionSets[key];
    addQuestion(
      $questionSets,
      questionSet.question,
      questionSet.answers,
      questionSet.correct_answer,
      {
        questionNumber: index + 1,
        questionsLength: Object.keys(questionSets).length,
      }
    );
  });
};
