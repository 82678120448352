/*----------------------------------------------------------------------------*\
  Shows or hides elements based on the value of a select control
\*----------------------------------------------------------------------------*/

export default function(options) {
  const _defaults = {
    selector: '.js-select-visibility',
    $selectors() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$selectors().length > 0;
    },
    confirmChangeSelector: 'js-confirm-onchange',
    triggerEvent: 'change',
  };

  const _settings = {
    ..._defaults,
    ...options,
  };

  // Current value of a select requiring confirmation to change
  let _currentValue;

  // Private method: Confirm change of select value
  const _confirmChange = event => {
    const $el = event.currentTarget;
    const newValue = $el.value;
    if (!confirm("Are you sure you want to change this user's role?")) {
      // Cancelled, set the value back to the stored value & return
      $el.value = _currentValue;
      return;
    }

    // Confirmed, update the value to the new one
    _currentValue = newValue;
  };

  const _changeVisibility = event => {
    const $el = event.currentTarget;
    const className = $el.dataset.toggleClass || 'u-hide';
    const conditionalValues = $el.dataset.conditionalValues.split(',');
    const targetSelectors = $el.dataset.targetSelectors.split(',');
    const editing = $el.dataset.editing;
    const originalValue = $el.dataset.originalValue;

    if (conditionalValues.length !== targetSelectors.length) {
      throw 'JS select visibility error: conditions vs targets length mismatch. Make sure you have the same number of conditions and targets';
    }

    // Hide all targets first
    let $targets = document.querySelectorAll(targetSelectors);
    $targets.forEach($target => {
      $target.classList.add(className);

      // Remove the required attribute from all hidden child controls and
      // store it as a data value
      let controls = $target.querySelectorAll('input[required],textarea[required]');
      controls.forEach(control => {
        control.required = null;

        // If the control is a file upload, check if there is a cache input as this is used for
        // server validation retention of the selected file.
        let cacheId = `${control.id}_cache`;
        let cache = document.getElementById(cacheId);

        if(cache == null || cache.value.length == 0) {
          control.dataset.required = "true";
        }
      });
    });

    // Then show the one target that has a true condition on selected value
    conditionalValues.forEach((conditionalValue, index) => {
      if ($el.value == conditionalValue) {
        let targetSelector = targetSelectors[index];
        let $targets = document.querySelectorAll(targetSelector);

        $targets.forEach($target => {
          $target.classList.remove(className);

          // Read the required data value and if present add to the child controls
          let controls = $target.querySelectorAll('input[data-required="true"],textarea[data-required="true"]');
          controls.forEach(control => {
            if(control.type == 'file' && editing && originalValue == conditionalValue) {
              // Don't add the required attribute if we are editing a file upload and the selection hasn't changed
            } else {
              control.required = "required";
            }
          });
        });
      }
    });
  };

  const destroy = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($select => {
      const triggerEvent = $select.dataset.event || _settings.triggerEvent;
      $select.removeEventListener(triggerEvent, _changeVisibility);
      $select.removeEventListener(_settings.triggerEvent, _confirmChange);
    });
  };

  const reinit = () => {
    destroy();
    init();
  };

  const init = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($select => {
      const triggerEvent = $select.dataset.event || _settings.triggerEvent;

      // If this select requires confirmation on change add the event listener
      if ($select.classList.contains(_settings.confirmChangeSelector)) {
        $select.addEventListener(_settings.triggerEvent, _confirmChange);
        // Store the current value of this select
        _currentValue = $select.value;
      }

      $select.addEventListener(triggerEvent, _changeVisibility);
      _changeVisibility({ currentTarget: $select });
    });
  };

  return {
    destroy,
    reinit,
    init,
  };
}
