/*----------------------------------------------------------------------------*\
  #VIDEO
\*----------------------------------------------------------------------------*/

// Imports
import { settings } from './settings';
import { createMarkup } from './util/create-markup';
import { removeMarkup } from './util/remove-markup';
import { videoHandler } from './methods/video-handler';
import { setProgress } from './methods/set-progress';
import { playPauseHandler } from './methods/play-pause-handler';
import { playbackComplete } from './methods/playback-complete';
import { clearCurrentTimeInterval } from './methods/clear-current-time-interval';
import { loadStartHandler } from './methods/load-start-handler';
import { preventScrubbing } from './methods/prevent-scrubbing';

const Video = () => {
  // Public: Destroy module instance
  const destroy = () => {
    if (!settings.exists()) return;

    settings.$selectors().forEach($videoContainer => {
      const $video = $videoContainer.querySelector('video');
      removeMarkup($videoContainer);
      $videoContainer.removeEventListener('click', videoHandler);
      $video.removeEventListener('play', setProgress);
      $video.removeEventListener('play', playPauseHandler);
      $video.removeEventListener('pause', playPauseHandler);
      $video.removeEventListener('pause', clearCurrentTimeInterval);
      $video.removeEventListener('ended', clearCurrentTimeInterval);
      $video.removeEventListener('ended', playbackComplete);
      $video.removeEventListener('loadstart', loadStartHandler);
      $video.removeEventListener('timeupdate', preventScrubbing);
    });
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!settings.exists()) return;

    settings.$selectors().forEach(($videoContainer, index) => {
      const $video = $videoContainer.querySelector('video');
      createMarkup($videoContainer, index);
      $videoContainer.addEventListener('click', videoHandler);
      $video.addEventListener('play', setProgress);
      $video.addEventListener('durationchange', setProgress);
      $video.addEventListener('play', playPauseHandler);
      $video.addEventListener('pause', playPauseHandler);
      $video.addEventListener('pause', clearCurrentTimeInterval);
      $video.addEventListener('ended', clearCurrentTimeInterval);
      $video.addEventListener('ended', playbackComplete);
      $video.addEventListener('loadstart', loadStartHandler);
      $video.addEventListener('timeupdate', preventScrubbing);
    });
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
};

// Default module export
export default Video;
