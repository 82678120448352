export const getPlaybackPosition = $progress => {
  const duration = $progress.max;
  const progressWidth = $progress.offsetWidth;
  const targetPos = event.offsetX;

  const percentage = (targetPos / progressWidth) * 100;
  const timeMilliseconds = duration * percentage;
  const timeSeconds = timeMilliseconds / 100;

  return {
    timeSeconds: timeSeconds,
    timeMilliseconds: timeMilliseconds,
    percentage: percentage,
  };
};
