import { settings } from '../settings';
import { createEl } from 'util/create-el';

const _showErrors = ($question, errorMessages) => {
  if (!errorMessages.length) return;

  const $errorDialog = createEl('div', {
    className: 'c-validationError',
    id: `${$question.id}_dialog`,
  });

  $errorDialog.setAttribute('role', 'alertdialog');
  $errorDialog.setAttribute('aria-labelledby', `${$question.id}_error_title`);
  $errorDialog.setAttribute('aria-describedby', `${$question.id}_error_desc`);

  const $errorTitle = createEl('h2', {
    className: 'c-validationError__title',
    id: `${$question.id}_error_title`,
    textContent:
      'There were some errors in the question below which prevented this question from being saved.',
  });

  const $errors = createEl('ul', {
    className: 'c-validationError__list',
    id: `${$question.id}_error_desc`,
  });

  errorMessages.forEach(error => {
    const $error = createEl('li', { textContent: error });
    $errors.appendChild($error);
  });

  $errorDialog.appendChild($errorTitle);
  $errorDialog.appendChild($errors);

  $question.parentNode.insertBefore($errorDialog, $question);
};

const _removeErrorMessages = () => {
  const $quiz = settings.$quiz();
  const $errors = $quiz.querySelectorAll('.c-validationError');
  $errors.forEach($error => $error.remove());
};

export const validateQuiz = event => {
  const $quiz = settings.$quiz();
  const $questions = $quiz.querySelectorAll('.js-quizQuestion');
  if (!$questions) return;

  _removeErrorMessages();

  let anyErrors = false;

  $questions.forEach($question => {
    const errorMessages = [];

    const hasTitle =
      $question.querySelector('.js-quizQuestionDiv').textContent.length > 0;
    if (!hasTitle) errorMessages.push('Please add a question title.');

    const $answers = $question.querySelectorAll('input[type="text"]');
    if ($answers.length < 2)
      errorMessages.push('Please add at least two answers to your question.');

    if ($answers.length >= 2) {
      const blank = Array.from($answers).some($answer => !$answer.value);
      if (blank)
        errorMessages.push(
          'Please ensure text has been added to every answer.'
        );

      const hasCorrectAnswer = $question.querySelector(
        'input[type="radio"]:checked'
      );
      if (!hasCorrectAnswer)
        errorMessages.push('Please define a correct answer.');
    }

    if (!errorMessages.length) return;

    _showErrors($question, errorMessages);

    anyErrors = true;
  });

  if (anyErrors) {
    event.preventDefault();
    event.stopPropagation();
  }
};
