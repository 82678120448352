import { updatePlaceholders } from '../methods/update-placeholders';

export const deleteItem = (event, $el, entity) => {
  const confirmDelete = confirm(
    `Are you sure you want to delete this ${entity}?`
  );

  if (confirmDelete === true) {
    $el.remove();
    updatePlaceholders();
    return;
  }
};
