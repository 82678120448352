import { getPlaybackPosition } from '../util/get-playback-position';
import { createNotice } from 'modules/shared/notice';

export const setPlaybackPosition = event => {
  event.stopPropagation();
  const $progress = event.currentTarget;
  const $videoContainer = $progress.closest('.js-video');
  const $video = $videoContainer.querySelector('video');

  if ($videoContainer.dataset.disableProgress === 'true') {
    createNotice('Please watch the video in its entirety.');
    return;
  }

  const position = getPlaybackPosition($progress).timeSeconds;

  $video.currentTime = position;
  $progress.value = position;
};
