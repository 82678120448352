/*----------------------------------------------------------------------------*\
  #DROPDOWN
\*----------------------------------------------------------------------------*/

// Default module export
export default function(options) {
  // Private: Default settings object
  const _defaults = {
    selector: '.js-dropdown',
    $dropdowns() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$dropdowns().length > 0;
    },
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Private: Open state of dropdown
  let _open = false;

  // Private: Toggle dropdown method
  const _toggleDropdown = event => {
    const $dropdown = event.currentTarget.closest('.js-dropdown');
    const $menu = $dropdown.querySelector('.c-dropdown__menu');

    $dropdown.classList.toggle('is-open');

    _open = !$dropdown.classList.contains('is-open');
    $menu.setAttribute('aria-hidden', _open);
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;

    const $buttons = document.querySelectorAll('.js-dropdownButton');
    $buttons.forEach($button =>
      $button.removeEventListener('click', _toggleDropdown)
    );
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;

    const $buttons = document.querySelectorAll('.js-dropdownButton');
    $buttons.forEach($button =>
      $button.addEventListener('click', _toggleDropdown)
    );
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
}
