import { correctAnswerHandler } from './correct-answer-handler';
import { wrongAnswerHandler } from './wrong-answer-handler';
import { getCorrectAnswer } from '../util/get-correct-answer';

export const answerHandler = event => {
  const $radio = event.currentTarget;
  const $answer = $radio.closest('.js-answer');
  const $questionSet = $answer.closest('.js-quizQuestionSet');
  $questionSet.classList.add('has-choice');

  const $radios = $questionSet.querySelectorAll('input[type="radio"]');
  $radios.forEach($radio => {
    $radio.disabled = true;
  });

  if (getCorrectAnswer(null, $answer)) {
    correctAnswerHandler($answer, $questionSet);
  } else {
    wrongAnswerHandler($answer, $questionSet);
  }
};
