/*----------------------------------------------------------------------------*\
  #QUIZ SHOW
\*----------------------------------------------------------------------------*/

// Imports
import { settings } from './settings';
import { fetchQuizData } from './util/fetch-quiz-data';
import { updateQuestionSetsHeight } from './methods/update-question-sets-height';

const QuizShow = () => {
  // Public: Destroy module instance
  const destroy = () => {
    if (!settings.$quiz()) return;
    settings.$quiz().innerHTML = '';
    window.removeEventListener('resize', updateQuestionSetsHeight);
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!settings.$quiz()) return;
    fetchQuizData();
    window.addEventListener('resize', updateQuestionSetsHeight);
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
};

// Default module export
export default QuizShow;
