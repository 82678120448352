import { createEl } from 'util/create-el';

export const createCloudFlareScript = videoId => {
  const $existingScript = document.querySelector(`#video_${videoId}`);
  if ($existingScript) $existingScript.remove();

  const $script = createEl('script', {
    defer: true,
    // original embed URL (now sometimes returns new API version, sometimes doesn't)
    src: `https://embed.cloudflarestream.com/embed/r4xu.fla9.latest.js?video=${videoId}`,

    // Preview link for testing purposes to force the new API:
    // src: `https://embed.cloudflarestream.com/embed/3f8m.fla9.latest.js?video=${videoId}`,
    id: `video_${videoId}`,
  });

  document.head.appendChild($script);
};
