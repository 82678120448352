/*----------------------------------------------------------------------------*\
  #UPLOAD
\*----------------------------------------------------------------------------*/

import { TE } from 'util/throw-error';
import { createEl } from 'util/create-el';
import Rails from '@rails/ujs';

// Default module export
export default function(options) {
  // Private: Default settings object
  const _defaults = {
    selector: '.js-upload',
    form: '.js-videoUploadForm',
    $selectors() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$selectors().length > 0;
    },
    hoverClass: 'is-dragHover',
    filesClass: 'c-uploadFiles',
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Private: Create uploaded files list markup
  const _createListMarkup = $upload => {
    let $uploadedFiles = $upload.querySelector(`.${_settings.filesClass}`);
    if ($uploadedFiles !== null) return;

    $uploadedFiles = document.createElement('ul');
    $uploadedFiles.className = _settings.filesClass;
    $upload.appendChild($uploadedFiles);
  };

  // Private: Remove list markup (used for destroy)
  const _removeListMarkup = $upload => {
    const $uploadedFiles = $upload.querySelector(`.${_settings.filesClass}`);
    if ($uploadedFiles === null) return;

    $uploadedFiles.remove();
  };

  // Private: Hover state
  const _hoverState = (event, $target = event.currentTarget) => {
    $target.closest(_settings.selector).classList.add(_settings.hoverClass);
  };

  // Private: Blur state
  const _blurState = (event, $target = event.currentTarget) => {
    $target.closest(_settings.selector).classList.remove(_settings.hoverClass);
  };

  const _validFile = (file, fileInput) => {
    if (fileInput.accept.length === 0) {
      return true;
    }

    const filename = file.name;
    const validExtensions = fileInput.accept.toLowerCase().split(',');
    const extension = filename.substr(filename.lastIndexOf('.')).toLowerCase();

    return validExtensions.indexOf(extension) >= 0;
  };

  // Private: Report files attached to input
  const _reportFiles = (
    event,
    $target = event.currentTarget,
    files = event.currentTarget.files
  ) => {
    const file = files[0];
    if (!file) return;

    const $label = $target.parentNode;
    const $form = document.querySelector(_settings.form);
    const $submit = $form.querySelector('.js-videoUploadButton');
    const $uploadedFiles = $label.querySelector(`.${_settings.filesClass}`);
    const $uploadName = document.querySelector(_settings.filesNameSelector);
    let $input = null;

    $uploadedFiles.innerHTML = '';

    if (!_validFile(file, $target)) {
      $target.value = ''; // Invalid file so clear the input
      $submit.disabled = true;
      return;
    }

    if ($uploadName) {
      $input = $uploadName.querySelector('.js-uploadNameInput');
    }

    if ($submit) {
      if (files.length === 0 && $input) {
        $input.required = false;
        $submit.disabled = true;
        return;
      }

      $submit.disabled = false;
    }

    [...files].map(file => {
      // Create list item
      let $item = document.createElement('li');
      $item.className = `${_settings.filesClass}__item`;
      $item.innerText = file.name;

      // Add item to uploaded files container
      $uploadedFiles.appendChild($item);
    });
  };

  // Private: Add video duration to the hidden redirect input
  const _addVideoDuration = event => {
    /**
     * Validate that there is a video file, catch any errors.
     */
    const $form = event.currentTarget.closest('form');
    const $videoFile = $form.querySelector('.js-videoFile');

    if (!$videoFile) return;

    const file = $videoFile.files[0];
    if (!_validFile(file, $videoFile)) return;

    const endpoint = $videoFile.dataset.updateDurationUrl;
    const method = $videoFile.dataset.updateDurationMethod.toUpperCase();

    if (!endpoint)
      TE(
        'No endpoint defined. This should be set as a data attribute on the input.'
      );
    if (!method)
      TE(
        'No method defined. This should be set as a data attribute on the input.'
      );

    // Prevent multiple submissions
    $form.removeEventListener('submit', _addVideoDuration);

    event.preventDefault();
    event.stopPropagation();

    // Prevent induction form being submitted while the video is uploading
    const $inductionSubmit = document.querySelector('.js-induction-edit-submit');
    if($inductionSubmit) {
      $inductionSubmit.disabled = true;
    }

    _createVideo(file, endpoint, method, $form);
  };

  // Prvate: Create video tag and capture duration
  const _createVideo = (file, endpoint, method, $form) => {
    // Create an element to retrieve the video duration
    const $video = createEl('video', {
      src: URL.createObjectURL(file),
    });

    // Listen for canplaythrough event to retrieve the duration
    $video.addEventListener('canplaythrough', event => {
      const duration = event.currentTarget.duration;
      const seconds = Math.ceil(duration);

      const formData = new FormData();
      formData.append('duration', seconds);

      const ops = {
        type: method,
        url: endpoint,
        data: formData,
      };

      _patchVideoDuration(ops, $form);
    });
  };

  // Private: Perform patch operation
  const _patchVideoDuration = (ops, $form) => {
    Rails.ajax({
      ...ops,
      success: () => $form.submit(),
      error: data => TE(data),
    });
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($upload => {
      const $form = $upload.closest('form');

      $form.removeEventListener('submit', _addVideoDuration);
      $upload.removeEventListener('dragenter', _hoverState);
      $upload.removeEventListener('dragleave', _blurState);
      $upload.removeEventListener('drop', _blurState);

      _removeListMarkup($upload);

      const $input = $upload.querySelector('input[type="file"]');
      $input.removeEventListener('change', _reportFiles);
    });
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($upload => {
      const $form = $upload.closest('form');

      $form.addEventListener('submit', _addVideoDuration);
      $upload.addEventListener('dragenter', _hoverState);
      $upload.addEventListener('dragleave', _blurState);
      $upload.addEventListener('drop', _blurState);

      _createListMarkup($upload);

      const $input = $upload.querySelector('input[type="file"]');
      _reportFiles(false, $input, $input.files);
      $input.addEventListener('change', _reportFiles);
    });
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
}
