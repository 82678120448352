/*----------------------------------------------------------------------------*\
  #NOTICE DIALOGS
\*----------------------------------------------------------------------------*/

// Imports
import { createEl } from 'util/create-el';

// Private: Default settings object
const _settings = {
  timeout: 8000,
  baseClass: 'c-notice',
  openClass: 'is-open',
  closeNoticeSelector: '.js-closeDialog',
  $closeNotices() {
    return document.querySelectorAll(this.closeNoticeSelector);
  },
  closeNoticesExist() {
    return this.$closeNotices().length > 0;
  },
  noticeSelector: '.js-notice',
  $notices() {
    return document.querySelectorAll(this.noticeSelector);
  },
  noticesExist() {
    return this.$notices().length > 0;
  },
};

// Public: Create notice element
export const createNotice = (
  html,
  role = 'dialog',
  timeout = _settings.timeout,
  id
) => {
  // Create a hash from the html content
  const hash = _hashCode(html);

  // Find any existing elements with this hash, rather than creating dups.
  let $notice = document.querySelector(
    `.${_settings.baseClass}[data-id="${hash}"]`
  );

  // If no element exists already, let's create it.
  if (!$notice) {
    $notice = createEl('div', {
      className: _settings.baseClass,
    });
    $notice.setAttribute('role', role);
    $notice.setAttribute('aria-labelledby', `${role}_${hash}`);

    const $noticeText = createEl('p', {
      textContent: html,
      className: `${_settings.baseClass}__text`,
      id: id || `${role}_${hash}`,
    });

    const $closeButton = createEl('button', {
      type: 'button',
      className: `${_settings.baseClass}__close`,
      title: `Close ${role === 'dialog' ? 'notice' : 'alert'}`,
      textContent: '✕',
    });

    $notice.dataset.id = _hashCode(html);

    $notice.appendChild($noticeText);
    $notice.appendChild($closeButton);
    document.body.appendChild($notice);

    $closeButton.addEventListener('click', _closeNotice);
  }

  displayNotice($notice, timeout);
};

// Private: Set a variable for our close notice timeout
let _closeNoticeTimeout;

// Public: Display notice method
export const displayNotice = ($notice, timeout = _settings.timeout) => {
  // Clear any existing timeouts to close the notice
  clearTimeout(_closeNoticeTimeout);

  $notice.removeEventListener('transitionend', _closeNotice);

  // Display our notice, with a small delay to ensure the CSS transition fires.
  setTimeout(() => {
    $notice.classList.add(_settings.openClass);
  }, 100);

  // Remove our notice
  _closeNoticeTimeout = setTimeout(() => {
    // Delete the DOM element once the transition is complete
    $notice.addEventListener('transitionend', _closeNotice);
    $notice.classList.remove(_settings.openClass);
  }, timeout);
};

// Private: Notice handler event
const _noticeHandler = (
  event,
  $target = event.target,
  content = event.target.dataset.noticeContent,
  timeout
) => {
  // If content is undefined let's not do anything
  if (content === undefined) return;

  // Use the data attribute if present, otherwise use the default from our settings
  timeout = $target.dataset.timeout || _settings.timeout;

  event.preventDefault();
  createNotice(content, timeout);
};

// Private: Close notice method
const _closeNotice = (event, $notice) => {
  $notice = $notice || event.currentTarget.closest('.c-notice');
  $notice.removeEventListener('transitionend', _closeNotice);
  $notice.classList.remove(_settings.openClass);
};

// Private: Create a hash code from a string
// Source: https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript#answer-15710692
const _hashCode = string => {
  return string.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

const _showOfflineNotice = () =>
  createNotice(
    "It looks like you've lost your Internet connection. You may need to reconnect to Wi-Fi",
    'alertdialog',
    undefined,
    'offline_notice'
  );

const _hideOfflineNotice = () => {
  const $notice = document.getElementById('offline_notice');
  _closeNotice(undefined, $notice);
};

// Default module export
const Notice = () => {
  // Public: Destroy module instance
  const destroy = () => {
    if (_settings.noticesExist()) {
      _settings.$notices().forEach($notice => {
        $notice.removeEventListener('click', _noticeHandler);
      });
    }

    if (_settings.closeNoticesExist()) {
      _settings.$closeNotices().forEach($closeButton => {
        $closeButton.removeEventListener('click', _closeNotice);
      });
    }

    window.removeEventListener('online', _hideOfflineNotice);
    window.removeEventListener('offline', _showOfflineNotice);
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (_settings.noticesExist()) {
      _settings.$notices().forEach($notice => {
        $notice.addEventListener('click', _noticeHandler);
      });
    }

    if (_settings.closeNoticesExist()) {
      _settings.$closeNotices().forEach($closeButton => {
        $closeButton.addEventListener('click', _closeNotice);
      });
    }

    window.addEventListener('online', _hideOfflineNotice);
    window.addEventListener('offline', _showOfflineNotice);
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
};

export default Notice;
