import { TE } from './throw-error';

/**
 * #SVG
 * Helper to include an SVG from our sprite
 *
 * @param   {Object} svg                      The SVG icon.
 * @param   {String | Array} [className=null] CSS class name(s) to add to the SVG.
 * @param   {String} [size=null]              Expects a rem value (ie. 1.6rem).
 * @returns {Node}                            SVG node containing the icon.
 */
export const svg = (svg, className = null, size = null) => {
  // If no icon is specified, do nothing
  if (!svg) TE('Please specify an svg.');
  if (typeof svg !== 'object')
    TE(
      `Invalid svg used. Expected to receive an Object, but got a ${typeof svg} instead.`
    );

  const icon = {
    name: svg
      .toString()
      .split('#')
      .pop(),
    path: svg.toString(),
  };

  // Set namespaces
  const svgNS = 'http://www.w3.org/2000/svg';
  const xlinkNS = 'http://www.w3.org/1999/xlink';

  // Create our elements
  const $svg = document.createElementNS(svgNS, 'svg');
  const $use = document.createElementNS(svgNS, 'use');

  // Add CSS class name(s)
  if (className) {
    // Make the param an array
    const classes =
      typeof className === 'string' ? [className] : Array.from(className);
    $svg.classList.add(...classes);
  }

  if (size) $svg.style.fontSize = size;
  $svg.setAttribute('aria-label', icon.name);
  $use.setAttributeNS(xlinkNS, 'xlink:href', icon.path);

  $svg.appendChild($use);

  return $svg;
};
