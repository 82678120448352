/*----------------------------------------------------------------------------*\
  #CLONE ELEMENT
  Currently for Qualifications, but could be refactored to accommodate
  different types of data in future.
\*----------------------------------------------------------------------------*/

// Imports
import { reinit as _reinit } from 'util/methods';
import { createEl } from 'util/create-el';

// Default module export
export default function(options) {
  // Private: Default settings object
  const _defaults = {
    selector: '.js-addNewEl',
    $selectors() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$selectors().length > 0;
    },
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Private: Add local storage content
  const _clone = event => {
    const target = event.currentTarget.dataset.target;
    const $el = document.getElementById(target);
    const $clone = $el.cloneNode(true);

    // Add a delete button
    _addDeleteButton($clone);

    // Remove ID
    $clone.removeAttribute('id');
    // Reset inputs
    $clone.querySelectorAll('input').forEach($input => ($input.value = ''));

    // Set input indices to be unique
    const ticks = new Date().getTime();
    $clone.querySelectorAll('input').forEach($input => {
      $input.name = $input.name.replace('[0]', `[${ticks}]`);
      $input.id = $input.id.replace('_0_', `_${ticks}_`);
    });

    $clone.querySelectorAll('.js-cloneableElIgnore').forEach($ignore => {
      $ignore.innerHTML = '';
    });

    // Append to DOM
    const $lastClone = $el.parentNode.querySelector(
      '.js-cloneableEl:last-of-type'
    );
    $el.parentNode.insertBefore($clone, $lastClone.nextElementSibling);

    // Reinit the 'Document Upload' module
    _reinit('application', 'documentUpload');
  };

  // Private: Add a delete button to the element
  const _addDeleteButton = $el => {
    const $inner = $el.querySelector('.js-cloneableElInner');

    const $gridContainer = createEl('div', {
      className: 'grid-x  grid-margin-y  grid-margin-x',
    });
    const $gridCell = createEl('div', { className: 'cell  auto  u-textEnd' });
    const $removeButton = createEl('button', {
      type: 'button',
      className:
        'c-button  c-button--outline  c-button--secondary  c-button--small',
      textContent: 'Remove this document',
    });

    $removeButton.addEventListener('click', () => _deleteItem($el));

    $gridCell.appendChild($removeButton);
    $gridContainer.appendChild($gridCell);

    $inner.appendChild($gridContainer);
  };

  // Private: Delete item handler
  const _deleteItem = $el => {
    const confirmDelete = confirm(
      'Are you sure you want to delete this qualification?'
    );

    if (confirmDelete === true) {
      $el.remove();
      return;
    }
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;

    document.querySelectorAll('.js-cloneableEl').forEach($el => {
      if (!$el.hasAttribute('id')) $el.remove();
    });

    _settings.$selectors().forEach($button => {
      $button.removeEventListener('click', _clone);
    });
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($button => {
      $button.addEventListener('click', _clone);
    });
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
}
