/*----------------------------------------------------------------------------*\
  #TOOLTIPS
\*----------------------------------------------------------------------------*/

// Imports
import { isInViewport } from 'util/is-in-viewport';

// Public: Hide tooltip
export const hideTooltip = (event, $tooltip, $tooltipContent) => {
  $tooltip = $tooltip || event.currentTarget;
  $tooltipContent =
    $tooltipContent || $tooltip.querySelector('.c-tooltip__content');

  if (!$tooltip || !$tooltipContent) return;

  $tooltip.classList.remove('is-visible');
  $tooltip.setAttribute('aria-expanded', 'false');
  $tooltipContent.setAttribute('aria-hidden', 'true');

  $tooltip.addEventListener('mouseenter', showTooltip);

  if (event && event.type === 'mouseleave') {
    $tooltipContent.addEventListener('transitionend', _removeModifier);
  }
};

// Public: Show tooltip
export const showTooltip = (event, $tooltip, $tooltipContent) => {
  $tooltip = $tooltip || event.currentTarget;
  $tooltipContent =
    $tooltipContent || $tooltip.querySelector('.c-tooltip__content');

  if (!$tooltip || !$tooltipContent) return;

  _tooltipIsInViewport($tooltip, $tooltipContent);
  $tooltip.classList.add('is-visible');
  $tooltip.setAttribute('aria-expanded', 'true');
  $tooltipContent.setAttribute('aria-hidden', 'false');

  if (event && event.type === 'mouseenter') {
    $tooltip.removeEventListener('mouseenter', showTooltip);
  }
};

// Public: Remove markup (used for destroy)
export const removeMarkup = $tooltip => {
  const $tooltipContent = $tooltip.querySelector('.c-tooltip__content');
  if ($tooltipContent) $tooltipContent.remove();
  $tooltip.removeAttribute('aria-labelledby');
  $tooltip.removeAttribute('aria-expanded');
  $tooltip.classList.remove('c-tooltip');
};

// Public: Create markup
export const createMarkup = ($tooltip, index) => {
  $tooltip.classList.add('c-tooltip');
  $tooltip.setAttribute('aria-labelledby', `tooltip_content_${index}`);

  // Get tooltip content
  const content = $tooltip.dataset.tooltip;

  // // Create our tooltip content element
  let $tooltipContent = document.createElement('p');
  $tooltipContent.className = 'c-tooltip__content';
  $tooltipContent.textContent = content;
  $tooltipContent.role = 'tooltip';
  $tooltipContent.id = `tooltip_content_${index}`;

  // Add our hidden state classes and aria attributes
  hideTooltip(false, $tooltip, $tooltipContent);

  // Insert into DOM
  $tooltip.appendChild($tooltipContent);
};

// Private: Check tooltip fits in viewport
const _tooltipIsInViewport = ($tooltip, $tooltipContent) => {
  if (!$tooltip || !$tooltipContent || $tooltip.dataset.tooltipPosition) return;

  const visible = isInViewport($tooltipContent);

  if (!$tooltip.classList.contains('c-tooltip--lhs') && !visible) {
    $tooltip.classList.add('c-tooltip--lhs');
  }
};

/**
 * Private: Remove modifier
 * When tooltip animates out, we remove the rhs modifier class.
 * This prevents the _toolTipisInView method returning a false positive.
 */
const _removeModifier = event => {
  const $tooltipContent = event.currentTarget;
  const $tooltip = $tooltipContent.closest('.c-tooltip');

  if (!$tooltip || !$tooltipContent) return;

  $tooltipContent.removeEventListener('transitionend', _removeModifier);
  $tooltip.classList.remove('c-tooltip--lhs');
};

// Default module export
export default function(options) {
  // Private: Default settings object
  const _defaults = {
    selector: '.js-tooltip',
    $selectors() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$selectors().length > 0;
    },
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($tooltip => {
      removeMarkup($tooltip);
      $tooltip.removeEventListener('mouseenter', showTooltip);
      $tooltip.removeEventListener('focus', showTooltip);
      $tooltip.removeEventListener('mouseleave', hideTooltip);
      $tooltip.removeEventListener('blur', hideTooltip);
    });
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach(($tooltip, index) => {
      createMarkup($tooltip, index);
      $tooltip.addEventListener('mouseenter', showTooltip);
      $tooltip.addEventListener('focus', showTooltip);
      $tooltip.addEventListener('mouseleave', hideTooltip);
      $tooltip.addEventListener('blur', hideTooltip);
    });
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
}
