import { settings } from '../settings';

export const updatePlaceholders = () => {
  const $quiz = settings.$quiz();
  const $questions = $quiz.querySelectorAll('.js-quizQuestion');

  $questions.forEach(($question, index) => {
    const $questionInput = $question.querySelector('.js-quizQuestionDiv');
    const $answers = $question.querySelectorAll('input[type="text"]');

    $questionInput.setAttribute('placeholder', `Insert question ${index + 1}…`);

    $answers.forEach(($answer, index) => {
      $answer.placeholder = `Answer ${index + 1}…`;
    });
  });
};
