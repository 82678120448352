/*----------------------------------------------------------------------------*\
  #ONESITE APPLICATION PACK
\*----------------------------------------------------------------------------*/

/**
 * ASSETS
 * Import assets for use in our app.
 */

// Styles
import 'application.scss';

/**
 * MODULES
 * Import modules for use in our app.
 */

import ToggleAside from 'modules/application/toggle-aside';
import Tables from 'modules/application/tables';
import Tooltips from 'modules/application/tooltips';
import Upload from 'modules/application/upload';
import DocumentUpload from 'modules/application/document-upload';
import Forms from 'modules/application/forms';
import QuizBuilder from 'modules/application/quiz-builder/main';
import QuizShow from 'modules/application/quiz-show/main';
import Video from 'modules/application/video/main';
import Induction from 'modules/application/induction';
import Dropdown from 'modules/application/dropdown';
import SelectVisibility from 'modules/application/select-dependent-visibility';
import Modals from 'modules/application/modals';
import Overlay from 'modules/application/overlay';
import LocalStorage from 'modules/application/local-storage';
import CloneEl from 'modules/application/clone-el';
import CopyEmergencyDetails from 'modules/application/copy-emergency-details';
import QRCodes from 'modules/application/qr';
import Breadcrumbs from 'modules/application/breadcrumbs';
import Textareas from 'modules/application/textareas';
import ButtonBgChange from 'modules/application/buttonBgChange';
// import LocalFormProgress from 'modules/application/local-form-progress';
import RejectInduction from 'modules/application/reject-induction';
import ToggleInductions from 'modules/application/toggle-inductions';
import TriggerUploadModal from 'modules/application/trigger-upload-modal';

import { methodHandler, getModule } from 'util/methods';

// Create a modules object holding all of our module instances
const modules = {
  toggleAside: new ToggleAside(),
  tables: new Tables(),
  tooltips: new Tooltips(),
  upload: new Upload(),
  documentUpload: new DocumentUpload(),
  forms: new Forms(),
  quizBuilder: new QuizBuilder(),
  quizShow: new QuizShow(),
  video: new Video(),
  induction: new Induction(),
  dropdown: new Dropdown(),
  SelectVisibility: new SelectVisibility(),
  modals: new Modals(),
  overlay: new Overlay(),
  localStorage: new LocalStorage(),
  cloneEl: new CloneEl(),
  CopyEmergencyDetails: new CopyEmergencyDetails(),
  QRCodes: new QRCodes(),
  breadcrumbs: new Breadcrumbs(),
  textareas: new Textareas(),
  buttonBgChange: new ButtonBgChange(),
  // localFormProgress: new LocalFormProgress(),
  rejectInduction: new RejectInduction(),
  toggleInductions: new ToggleInductions(),
  triggerUploadModal: new TriggerUploadModal(),
};

/**
 * METHODS
 * We create events to hook into these methods so we can trigger
 * them from individual modules.
 */

// Init method
const init = event => {
  const module = getModule(event);
  methodHandler('init', module, modules);
};

// Reinit method
const reinit = event => {
  const module = getModule(event);
  methodHandler('reinit', module, modules);
};

// Destroy method
const destroy = event => {
  const module = getModule(event);
  methodHandler('destroy', module, modules);
};

// Trigger methods from events
document.addEventListener('application:init', init);
document.addEventListener('application:reinit', reinit);
document.addEventListener('application:destroy', destroy);
document.addEventListener('turbolinks:load', reinit);

import "controllers"
