/*----------------------------------------------------------------------------*\
#BREADCRUMBS
\*----------------------------------------------------------------------------*/
const routeHistory = [];

const Breadcrumbs = () => {
  // Private: Default settings object
  const _settings = {
    activeClass: 'is-active',
    breadcrumbsSelector: '.js-breadcrumbs',
    currentItemSelector: '.js-breadcrumbsCurrent',
  };

  const $breadcrumbs = () =>
    document.querySelectorAll(_settings.breadcrumbsSelector);
  let isMobile = window.matchMedia('(max-width: 767px)');

  // Private: Toggle breadcrumbs
  const _handleClick = e => {
    const parentCumbs = e.target.closest(_settings.breadcrumbsSelector);
    parentCumbs.classList.toggle(_settings.activeClass);
  };

  function updateHistory(event) {
    routeHistory.push(event.data.url);
  }

  function canGoBack() {
    if (routeHistory.length < 2) return;

    const prevUrl = routeHistory[routeHistory.length - 2];
    const currentDomain = window.location.origin;
    const referrerDomain = new URL(prevUrl).origin;

    return currentDomain === referrerDomain;
  }

  function goBack() {
    if (!canGoBack()) return;
    routeHistory.pop();
    window.history.back();
  }

  // Public: Destroy module instance
  const destroy = () => {
    document.querySelectorAll('.js-nav-back').forEach(node => {
      node.removeEventListener('click', goBack);
    });

    document.removeEventListener('turbolinks:before-visit', updateHistory);

    if (!$breadcrumbs()) return;
    // Remove any event listeners
    $breadcrumbs().forEach($breadcrumbsInstance => {
      const $toggle = $breadcrumbsInstance.querySelector(
        _settings.currentItemSelector
      );
      $toggle.removeEventListener('click', _handleClick);
    });
  };

  const _setupEventListeners = () => {
    if (!routeHistory.length) routeHistory.push(window.location.href);

    document.querySelectorAll('.js-nav-back').forEach(node => {
      canGoBack()
        ? node.classList.remove('u-hide')
        : node.classList.add('u-hide');
      node.addEventListener('click', goBack);
    });

    document.addEventListener('turbolinks:before-visit', updateHistory);

    if (!isMobile.matches) {
      // We're not on mobile, clean up any event listeners, we don't want them.
      $breadcrumbs().forEach($breadcrumbsInstance => {
        // We're not on mobile, remove any active classes that might be present
        $breadcrumbsInstance.classList.remove(_settings.activeClass);
        // Remove event listeners
        const $toggle = $breadcrumbsInstance.querySelector(
          _settings.currentItemSelector
        );
        $toggle.removeEventListener('click', _handleClick);
      });
    } else {
      // We're on mobile, set up event listeners
      $breadcrumbs().forEach($breadcrumbsInstance => {
        const $toggle = $breadcrumbsInstance.querySelector(
          _settings.currentItemSelector
        );
        $toggle.addEventListener('click', _handleClick);
      });
    }
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!$breadcrumbs()) return;
    isMobile.addListener(_setupEventListeners);
    _setupEventListeners();
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
};

// Default module export
export default Breadcrumbs;
