import { updateQuestionInputValue } from './update-queston-input-value';
import { deleteQuestion } from './delete-question';

export const questionNameHandler = event => {
  const $target = event.currentTarget;

  updateQuestionInputValue(event);

  // Backspace
  if (
    (event.code === 'Backspace' || event.which === 8) &&
    $target.textContent.length === 0
  ) {
    deleteQuestion(event);
  }
};
