/*----------------------------------------------------------------------------*\
  #TABLES
  Responsive tables, and track mouse interactions
\*----------------------------------------------------------------------------*/

// Default module export
export default function(options) {
  // Private: Default settings object
  const _defaults = {
    selector: '.js-table',
    $selectors() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$selectors().length > 0;
    },
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Private: Add title attributes to table for mobile
  const _addTitleAttributes = $table => {
    // Get headings
    const $headings = $table.querySelectorAll('thead > tr > th');

    // Set an initial starting column
    let startCol = 1;

    $headings.forEach($heading => {
      // Find columns in every row
      const $cols = $table.querySelectorAll(
        `tbody > tr > td:nth-child(${startCol})`
      );

      // Set titles
      $cols.forEach($col => {
        $col.dataset.title = $heading.innerText;
      });

      // Increment our startCol
      startCol += $heading.colSpan;
    });
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($table => {
      // Check we have more than 1 row
      const $tableRows = $table.querySelectorAll('tbody > tr');
      if ($tableRows.length <= 1) return;
    });
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($table => {
      _addTitleAttributes($table);

      // Check we have more than 1 row
      const $tableRows = $table.querySelectorAll('tbody > tr');
      if ($tableRows.length <= 1) return;
    });
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
}
