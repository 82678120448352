import { settings } from '../settings';
import { updateProgress } from '../../updateProgress';

const _displayConfirmation = () => {
  const $quiz = settings.$quiz();
  const $confirmation = document.querySelector('.js-quizConfirmation');

  setTimeout(() => {
    $quiz.innerHTML = null;
    $confirmation.classList.remove('is-hidden');
  }, 1000);
};

export const quizConfirmation = () => {
  const $confirmation = document.querySelector('.js-quizConfirmation');
  if (!$confirmation) return;

  updateProgress(2);

  const $confirmCheckbox = $confirmation.querySelector(
    '.js-quizConfirmationCheckbox'
  );
  const $finishButton = $confirmation.querySelector(
    '.js-quizConfirmationButton'
  );

  _displayConfirmation();

  $confirmCheckbox.addEventListener('change', event => {
    if ($confirmCheckbox.checked) {
      $finishButton.disabled = false;
    } else {
      $finishButton.disabled = true;
    }
  });
};
