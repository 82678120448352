import { svg } from 'util/svg';
import { createEl } from 'util/create-el';
import deleteIcon from 'images/icons/delete.svg';
import { timeStamp } from '../util/timestamp';
import { getName } from '../util/get-name';
import { getId } from '../util/get-id';
import { answerHandler } from '../methods/answer-handler';
import { deleteAnswer } from '../methods/delete-answer';

/**
 * #ADD ANSWER
 * @param   {Event}   event
 * @param   {Object}  answer  Answer content, if undefined we produce a blank answer.
 * @param   {Node}    $target Target to append the answer to, if undefined we try to determine this from the event.
 * @returns {Node}
 */
export const addAnswer = (event, answer, $target) => {
  // Define the defaults here for a blank answer.
  const blankAnswer = {
    id: `answer_${timeStamp()}`,
    value: null,
    correct: false,
  };

  // If no answer is passed through, we set it the values for a blank answer.
  if (!answer) answer = blankAnswer;

  // Use the `$target` value if it has been defined, otherwise determine this from the event.
  $target = $target || event.currentTarget.closest('.js-quizQuestion');

  // Set the name attribute
  const baseName = getName(null, $target) + '[answers][]';

  // Get the Question ID
  const questionId = getId(null, $target);

  // Determine how many answers are currently in the question so that we can use this in our placeholder.
  const answersLength = $target
    .closest('.js-quizQuestion')
    .querySelectorAll('input[type="radio"]').length;

  // Grid
  const $radioGridContainer = createEl('div', {
    className:
      'c-quizAdmin__answer  grid-x  grid-margin-y  grid-margin-x  js-quizAnswer',
  });
  const $radioGridCellAuto = createEl('div', { className: 'cell  auto' });
  const $radioGridCellShrink = createEl('div', { className: 'cell  shrink' });

  $radioGridContainer.appendChild($radioGridCellAuto);
  $radioGridContainer.appendChild($radioGridCellShrink);

  // Radio
  const $radioLabel = createEl('label', {
    htmlFor: `${questionId}_input_${answersLength + 1}`,
    className: 'c-quizAdmin__label',
  });
  const $deleteButtonSvg = svg(deleteIcon, null, '2rem');

  // Hidden
  const $answerInputId = createEl('input', {
    name: `${baseName}[id]`,
    type: 'hidden',
    value: answer.id,
  });

  const $radioWrapper = createEl('div', { className: 'o-checkbox' });

  const $radioInput = createEl('input', {
    type: 'radio',
    name: `${baseName}[correct]`,
    id: `${questionId}_input_${answersLength + 1}`,
    value: 'true',
    checked: answer.correct,
  });

  const $radioTextInput = createEl('input', {
    type: 'text',
    placeholder: `Answer ${answersLength + 1}…`,
    name: `${baseName}[value]`,
    className: 'c-subtleInput',
    value: answer.value,
  });

  const $deleteButton = createEl('button', {
    className: 'js-tooltip',
    type: 'button',
  });

  $deleteButton.dataset.tooltip = 'Delete this answer';

  $deleteButton.appendChild($deleteButtonSvg);
  $radioWrapper.appendChild($answerInputId);
  $radioWrapper.appendChild($radioInput);
  $radioWrapper.appendChild($radioLabel);
  $radioWrapper.appendChild($radioTextInput);

  $radioGridCellShrink.appendChild($deleteButton);
  $radioGridCellAuto.appendChild($radioWrapper);
  $target.insertBefore($radioGridContainer, $target.lastElementChild);

  if (!answer.value) $radioTextInput.focus();

  // Events
  $radioTextInput.addEventListener('keydown', answerHandler);
  $deleteButton.addEventListener('click', deleteAnswer);
};
