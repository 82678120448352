export const setProgress = event => {
  const $video = event.currentTarget;
  const $videoContainer = $video.closest('.js-video');

  if (!$video.duration) return;

  $videoContainer.classList.add('has-duration');

  const $progress = $videoContainer.querySelector('progress');

  $progress.max = $video.duration;
  $progress.value = $video.currentTime;
};
