export const enableFullScreen = event => {
  event.stopPropagation();

  const iOS =
    /(iPad|iPhone|iPod)/g.test(navigator.userAgent) && !window.MSStream;
  const $button = event.currentTarget;
  const $video = $button.closest('.js-video').querySelector('video');
  if (!$video) return;
  if (iOS) {
    $video.webkitSetPresentationMode('fullscreen');
  } else {
    $video.requestFullscreen();
  }
};
