/*----------------------------------------------------------------------------*\
  #TOGGLE ASIDE
\*----------------------------------------------------------------------------*/

// Imports
import { $overlay } from './overlay';
import { trapFocus, restoreFocus } from './trap-focus';

// Default module export
export default function(options) {
  // Private: Default settings object
  const _defaults = {
    selector: '.js-toggleAside',
    $selectors() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$selectors().length > 0;
    },
    openClass: 'is-openCanvas', // Scoped to body
    activeClass: 'is-active', // Hamburger icon specific
    $hamburger() {
      return document.querySelector(`${this.selector}.hamburger`);
    },
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Private: Close offcanvas
  const _closeOffCanvas = event => {
    if (event) event.preventDefault();

    document.body.classList.remove(_settings.openClass);
    _settings.$hamburger().classList.remove(_settings.activeClass);
    _settings.$hamburger().setAttribute('aria-expanded', 'false');

    restoreFocus();
  };

  // Private: Open offcanvas
  const _openOffCanvas = event => {
    if (event) event.preventDefault();

    document.body.classList.add(_settings.openClass);
    _settings.$hamburger().classList.add(_settings.activeClass);
    _settings.$hamburger().setAttribute('aria-expanded', 'true');

    trapFocus();
  };

  // Private: Hide the off-canvas if the viewport width exceeds 767px
  const _hideOffCanvasForLarge = () => {
    if (window.innerWidth > 767) _closeOffCanvas();
  };

  // Private: Toggle aside method
  const _toggleAside = event => {
    if (event) event.preventDefault();

    const open = document.body.classList.contains(_settings.openClass);
    open ? _closeOffCanvas() : _openOffCanvas();
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($toggle => {
      $toggle.addEventListener('click', _toggleAside);
    });

    $overlay().removeEventListener('click', _closeOffCanvas);
    window.removeEventListener('resize', _hideOffCanvasForLarge);
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($toggle => {
      $toggle.addEventListener('click', _toggleAside);
    });

    $overlay().addEventListener('click', _closeOffCanvas);
    window.addEventListener('resize', _hideOffCanvasForLarge);
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
}
