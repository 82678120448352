import { createEl } from 'util/create-el';
import { svg } from 'util/svg';
import { updateProgress } from './updateProgress';
import checkIcon from 'images/icons/check.svg';
import { createCloudFlareScript } from './video/util/create-cloudflare-script';
import { reinit as _reinit } from 'util/methods';
import videojs from 'video.js';

const _getVideoId = $induction => $induction.dataset.videoId;
const _getInductionId = $induction => $induction.dataset.inductionId;
const _getEndpoint = inductionId =>
  document.querySelector(`.js-induction[data-induction-id="${inductionId}"]`)
    .dataset.endpoint;

const _videoCompleteMarkup = (event, $videoContainer) => {
  $videoContainer = $videoContainer || event.currentTarget.closest('.js-video');

  const $induction = $videoContainer.closest('.js-induction');
  const inductionId = _getInductionId($induction);

  const $videoIconWrap = createEl('figure', {
    className: 'c-check',
  });

  const $videoIcon = svg(checkIcon, 'c-check__icon');

  const $videoText = createEl('div', {
    className: 'c-video__text',
  });

  const $videoMain = createEl('div', {
    className: 'c-video__main',
  });

  const $videoCta = createEl('div', {
    className: 'c-video__cta',
  });

  const $videoPara = createEl('p', {
    textContent: 'Video complete. Now for a few questions…',
  });

  const $beginQuizButton = createEl('button', {
    className: 'c-button  [ c-button--expanded  c-button--large ]',
    type: 'button',
    textContent: 'Begin Quiz',
  });

  $beginQuizButton.setAttribute('aria-controls', inductionId);
  $beginQuizButton.addEventListener('click', _beginQuiz);

  $videoIconWrap.appendChild($videoIcon);
  $videoMain.appendChild($videoIconWrap);
  $videoMain.appendChild($videoPara);
  $videoCta.appendChild($beginQuizButton);

  $videoText.appendChild($videoMain);
  $videoText.appendChild($videoCta);

  $videoContainer.appendChild($videoText);
};

const _videoCompleteHandler = (event, $video) => {
  $video = $video || event.currentTarget;
  const $videoContainer = $video.closest('.js-video');
  $videoContainer.classList.add('has-text');
};

const _beginQuiz = event => {
  event.preventDefault();
  event.stopPropagation();

  updateProgress(1);

  const $induction = event.currentTarget.closest('.js-induction');
  const $video = $induction.querySelector('.js-video');
  const $videoWrap = $video.closest('.js-videoWrap');

  if ($video.fullScreen) document.exitFullscreen();
  $videoWrap.classList.add('is-hidden');
  $videoWrap.style.height = `${$videoWrap.offsetHeight}px`;
  $videoWrap.dataset.naturalHeight = `${$videoWrap.offsetHeight}px`;

  setTimeout(() => {
    $videoWrap.style.height = 0;
  }, 300);

  setTimeout(() => {
    _showQuiz(null, $induction);
    _addReturnToQuizButton($videoWrap);
    _addReturnToQuizText($video.querySelector('.c-video__text'));
  }, 700);
};

const _addReturnToQuizButton = $videoWrap => {
  const $existingButton = $videoWrap.querySelector(
    '.c-videoWrap__returnToQuiz'
  );

  if ($existingButton) {
    return;
  }

  const $newButton = createEl('button', {
    className: 'c-button c-videoWrap__returnToQuiz',
    type: 'button',
    textContent: 'Return to Quiz',
  });

  $newButton.addEventListener('click', _beginQuiz);

  $videoWrap.append($newButton);
};

const _addReturnToQuizText = $videoText => {
  const $main = $videoText.querySelector('.c-video__main');
  if (!$main) return;

  const $button = $videoText.querySelector('.c-button');
  $button.textContent = 'Return to Quiz';
  $main.remove();
};

const _showVideo = (event, $videoContainer) => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }

  updateProgress(0);

  $videoContainer =
    $videoContainer ||
    event.currentTarget.closest('.js-induction').querySelector('.js-video');
  const $video = $videoContainer.querySelector('video');
  const $videoWrap = $videoContainer.closest('.js-videoWrap');
  const $quiz = $videoContainer.closest('.js-induction').querySelector('.js-quizShow');

  $quiz.setAttribute('aria-hidden', true);
  $quiz.classList.add('is-hidden');

  $videoWrap.style.height = $videoWrap.dataset.naturalHeight;
  $videoWrap.removeEventListener('transitionend', _showQuiz);
  $videoWrap.addEventListener('transitionend', _removeVideoHeight);
  $videoWrap.scrollIntoView({ behavior: 'smooth' });
  $video.currentTime = 0;
  $video.play();
};

const _removeVideoHeight = (event, $video) => {
  $video =
    $video ||
    event.currentTarget.closest('.js-induction').querySelector('.js-video');
  const $videoWrap = $video.closest('.js-videoWrap');

  $videoWrap.style.removeProperty('height');
  $videoWrap.classList.remove('is-hidden');

  $videoWrap.removeEventListener('transitionend', _removeVideoHeight);
};

const _showQuiz = (event, $induction) => {
  $induction = $induction || event.currentTarget.closest('.js-induction');
  const $quiz = $induction.querySelector('.js-quizShow');
  const $video = $induction.querySelector('.js-video');

  $quiz.classList.remove('is-hidden');
  $quiz.setAttribute('aria-hidden', 'false');

  if (!$video) return;
  const $rewatchButton = $quiz.querySelector('.js-watchVideo');
  $video.removeAttribute('data-disable-progress');
  $video.classList.remove('has-text');

  if ($rewatchButton) $rewatchButton.addEventListener('click', _showVideo);
};

const _hideQuiz = $quiz => {
  $quiz.classList.add('is-hidden');
  $quiz.setAttribute('aria-hidden', 'true');
};

const _resetQuiz = event => {
  const $induction = event.currentTarget.closest('.js-induction');
  const $video = $induction.querySelector('.js-video');
  const $quiz = $induction.querySelector('.js-quizShow');

  if ($video) {
    $video.removeEventListener('transitionend', _showQuiz);
    $video.querySelector('video').play();

    _showVideo(null, $video);

    _reinit('application', 'quizShow');
    _hideQuiz($quiz);
    return;
  }

  _reinit('application', 'quizShow');
};

const _addVideo = $induction => {
  const videoId = _getVideoId($induction);

  const $videoWrap = createEl('figure', {
    className: 'c-videoWrap  js-videoWrap',
  });

  const $videoContainer = createEl('video-js', {
    className: 'js-video',
  });

  const $source = createEl('source', {
    src: `https://customer-o5iipnh88x9w3l8p.cloudflarestream.com/${videoId}/manifest/video.m3u8`,
    type: 'application/x-mpegURL',
  });

  if ($induction.dataset.disableProgress !== null)
    $videoContainer.dataset.disableProgress = $induction.dataset.disableProgress;

  $videoContainer.appendChild($source);
  $videoWrap.appendChild($videoContainer);
  $induction.appendChild($videoWrap);

  videojs($videoContainer, { controlBar: false });

  const $video = $videoContainer.querySelector('video')

  // If the video is 'near enough' to the end to trigger 'complete'
  $video.addEventListener('timeupdate', ({ target }) => {
    const roundedTimeIsEnd =
      Math.round(target.currentTime) === Math.round(target.duration);
    roundedTimeIsEnd && _videoCompleteHandler(null, target);
  });

  $video.addEventListener('ended', _videoCompleteHandler);

  _videoCompleteMarkup(null, $videoContainer);
};

const _addVideoTitle = event => {
  const $induction = event.detail.$induction;
  if ($induction.querySelector('.js-inductionTitle')) return;

  const $videoWrap = $induction.querySelector('.js-videoWrap');
  const $video = $induction.querySelector('.js-video');

  const questionsLength = event.detail.questionsLength;
  const multipleQuestions = questionsLength > 1;

  const textContent = multipleQuestions
    ? `Please watch the video below, as we will be testing your
      knowledge at the end with ${questionsLength} multiple choice questions.`
    : `Please watch the video below, as we will be testing your
      knowledge at the end with a multiple choice question.`;

  const $title = createEl('header', {
    className:
      'c-induction__panel  c-induction__panel--inset  js-inductionTitle',
    textContent,
  });

  if ($videoWrap && $video) {
    $videoWrap.insertBefore($title, $video);
  }
};

const _addQuiz = ($induction, video) => {
  const inductionId = _getInductionId($induction);
  const endpoint = _getEndpoint(inductionId);

  const $quiz = createEl('div', {
    className: 'js-quizShow',
    id: `induction_${inductionId}`,
  });

  if (video) {
    $quiz.classList.add('is-hidden');
    $quiz.setAttribute('aria-hidden', 'true');
    $quiz.dataset.video = 'true';
  }

  $quiz.dataset.inductionId = inductionId;
  $quiz.dataset.endpoint = endpoint;
  $quiz.addEventListener('quiz:reset', _resetQuiz);
  $quiz.addEventListener('quiz:fetchData', _addVideoTitle);

  $induction.appendChild($quiz);
};

const Induction = () => {
  // Public: Destroy module instance
  const destroy = () => {
    const $induction = document.querySelector('.js-induction');
    if (!$induction) return;

    $induction.innerHTML = '';
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    const $induction = document.querySelector('.js-induction');
    if (!$induction) return;

    const videoId = _getVideoId($induction);

    if (videoId) {
      _addVideo($induction);
      createCloudFlareScript(videoId);
    }

    _addQuiz($induction, videoId);

    _reinit('application', 'video');
    _reinit('application', 'quizShow');
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
};

export default Induction;
