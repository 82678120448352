import { Controller } from 'stimulus';
import { debounce } from 'lodash-es';

// Connects to data-controller="users-search"
export default class extends Controller {
  static targets = ['input', 'results', 'result'];

  connect() {
    this.inputTarget.addEventListener(
      'keyup',
      debounce(this.search.bind(this), 300)
    );
    document.addEventListener('click', this.handleClickOutside.bind(this));
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this));
  }

  search() {
    const query = this.inputTarget.value;
    if (query.length < 3) {
      this.hideResults();
      return;
    }

    fetch(`/customers/users/search?query=${encodeURIComponent(query)}`, {
      method: 'GET',
      headers: { Accept: 'text/vnd.turbo-stream.html' },
    })
      .then((response) => response.text())
      .then((html) => (this.resultsTarget.innerHTML = html));
  }

  selectResult(event) {
    const userEmail = event.currentTarget.dataset.userEmail;
    this.inputTarget.value = userEmail;
    this.hideResults();
  }

  handleClickOutside(event) {
    const withinComponent = this.element.contains(event.target);
    if (!withinComponent) {
      this.hideResults();
    }
  }

  hideResults() {
    this.resultsTarget.innerHTML = '';
  }
}
