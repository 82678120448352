/*----------------------------------------------------------------------------*\
  #LOCAL STORAGE
\*----------------------------------------------------------------------------*/

// Default module export
export default function(options) {
  // Private: Default settings object
  const _defaults = {
    selector: '.js-getLocalStorage',
    $selectors() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$selectors().length > 0;
    },
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Private: Add local storage content
  const _addLocalStorageContent = $el => {
    const localStorageItem = localStorage.getItem($el.dataset.storage);
    if (localStorageItem !== null) $el.textContent = localStorageItem;
  };

  // Private: Remove text content
  const _removeTextContent = $el => {
    $el.textContent = $el.dataset.fallback || '';
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($el => {
      _removeTextContent($el);
    });
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($el => {
      _addLocalStorageContent($el);
    });
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
}
