/*----------------------------------------------------------------------------*\
  #QUIZ BUILDER
\*----------------------------------------------------------------------------*/

// Imports
import { settings } from './settings';
import { addQuestionButton } from './components/question-button';
import { fetchExistingQuiz } from './util/fetch-existing-quiz';
import { validateQuiz } from './methods/validate-quiz';

const QuizBuilder = () => {
  // Public: Destroy module instance
  const destroy = () => {
    if (!settings.$quiz()) return;
    settings.$quiz().innerHTML = '';
    const $form = settings.$quiz().closest('form');
    $form.removeEventListener('submit', validateQuiz);
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!settings.$quiz()) return;
    addQuestionButton();
    fetchExistingQuiz();
    const $form = settings.$quiz().closest('form');
    $form.addEventListener('submit', validateQuiz);
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
};

// Default module export
export default QuizBuilder;
