export const videoHandler = event => {
  const $videoContainer = event.currentTarget;
  const $video = $videoContainer.querySelector('video');

  if (
    $videoContainer.closest('.c-video__controls') ||
    !$videoContainer.classList.contains('is-ready')
  )
    return;

  if ($video.playing) {
    $video.pause();
  } else {
    $video.play();
  }
};
