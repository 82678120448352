import { settings } from '../settings';

export const wrongAnswerHandler = ($answer, $questionSet) => {
  const $quiz = settings.$quiz();
  const $progress = $questionSet.querySelector('.js-quizProgress');
  const $continueButton = $quiz.querySelector('.js-quizContinue');
  const $rewatchButton = $quiz.querySelector('.js-watchVideo');
  const $resetButton = $quiz.querySelector('.js-resetQuiz');

  $progress.textContent = 'Wrong answer!';
  $questionSet.classList.add('is-wrong');

  $continueButton.parentNode.remove();
  if ($rewatchButton) $rewatchButton.parentNode.remove();
  $resetButton.parentNode.classList.remove('u-hide');
};
