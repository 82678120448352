// Private: Default settings object
const _$trapEls = [
  document.querySelector('.c-app__head'),
  document.querySelector('.c-app__body'),
];

// Public: Focusable Elements
export const focusableEls = [
  'a[href]',
  'area[href]',
  'input:not([disabled])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'button:not([disabled])',
  'iframe',
  'object',
  'embed',
  '[contenteditable]',
  '[tabindex]:not([tabindex^="-"])',
];

// Public: Close offcanvas
export const restoreFocus = ($els = _$trapEls) => {
  $els.forEach($el => {
    _toggleTabIndex($el, true);
    $el.setAttribute('aria-hidden', 'false');
  });
};

// Public: Open offcanvas
export const trapFocus = ($els = _$trapEls) => {
  $els.forEach($el => {
    _toggleTabIndex($el, false);
    $el.setAttribute('aria-hidden', 'true');
  });
};

// Public: Find all focusable elements from a given selector
export const getFocusableElements = $el => {
  return Array.prototype.slice.call(
    $el.querySelectorAll(focusableEls.join(','))
  );
};

// Private: Make all focusable elements tabbable
const _makeTabbable = $el => {
  $el.removeAttribute('tabindex');
};

// Private: Make all focusable elements untabbable
const _makeUntabbable = $el => {
  $el.setAttribute('tabindex', -1);
};

// Private: Toggle the tabIndex
const _toggleTabIndex = ($parent, focusable) => {
  const $els = getFocusableElements($parent);

  focusable ? $els.forEach(_makeTabbable) : $els.forEach(_makeUntabbable);
};
