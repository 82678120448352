import { createEl } from 'util/create-el';
import { setPlaybackPosition } from '../methods/set-playback-position';
import { displayPlaybackPosition } from '../methods/display-playback-position';
import { hidePlaybackPosition } from '../methods/hide-playback-position';

export const progress = () => {
  const $progress = createEl('progress', {
    className: 'c-video__progress',
  });

  $progress.addEventListener('click', setPlaybackPosition);
  $progress.addEventListener('mousemove', displayPlaybackPosition);
  $progress.addEventListener('mouseout', hidePlaybackPosition);

  return $progress;
};
