/*----------------------------------------------------------------------------*\
  #MODALS
\*----------------------------------------------------------------------------*/

// Imports
import { $overlay, closeOverlay } from './overlay';
import { createEl } from 'util/create-el';
import { trapFocus, restoreFocus, getFocusableElements } from './trap-focus';

const Modals = options => {
  // Private: Default settings object
  const _defaults = {
    selector: '.js-progressModal',
    $selectors() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$selectors().length > 0;
    },
    $openModals() {
      return document.querySelectorAll(`${this.selector}.${this.openClass}`);
    },
    hasOpenModals() {
      return this.$openModals().length > 0;
    },
    multipleOpenModals() {
      return this.$openModals().length > 1;
    },
    $modals() {
      return document.querySelector('.js-modals');
    },
    $closeButtons() {
      return document.querySelectorAll('.js-closeModal');
    },
    openClass: 'is-open',
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  const _$trapEls = () => {
    return [
      document.querySelector('.c-app__head'),
      document.querySelector('.c-app__main'),
      document.querySelector('.c-app__aside'),
      ..._settings.$selectors(),
    ];
  };

  const _$currentOpenModal = () =>
    Array.from(
      document.querySelectorAll(`${_settings.selector}.${_settings.openClass}`)
    ).pop();

  const _closeModals = event => {
    event.preventDefault();
    event.stopPropagation();

    if (event.detail.open) return;

    restoreFocus(_$trapEls());
    $overlay().removeEventListener('overlay:transitionstart', _closeModals);

    _settings.$modals().setAttribute('aria-hidden', 'true');
    _settings.$modals().classList.remove(_settings.openClass);
    document.body.classList.remove(`${_settings.openClass}Modals`);

    _settings.$selectors().forEach($modal => {
      $modal.style.display = 'none';
      $modal.setAttribute('aria-hidden', 'true');
    });
  };

  const _offsetOpenModals = () => {
    if (!_settings.hasOpenModals()) return;

    if (_settings.multipleOpenModals()) {
      _offsetModals(_settings.$openModals());
    } else {
      _settings.$openModals()[0].removeAttribute('style');
    }
  };

  const _removeOpenCurrentState = $modal => {
    if (!$modal) return;

    $modal.setAttribute('aria-hidden', 'true');
    $modal.classList.remove('is-current');
  };

  const _setOpenCurrentState = $modal => {
    if (!$modal) return;

    $modal.setAttribute('aria-hidden', 'false');
    $modal.classList.add('is-current');
  };

  const _offsetModals = $modals => {
    const $modalsReversed = Array.from($modals).reverse();
    $modalsReversed.forEach(($modal, i) => {
      $modal.removeAttribute('style');
      const scale = (100 - i * 5) / 100;

      if (scale < 1) {
        $modal.style.cssText = `transform: scale(${scale}) translateY(${i}rem)`;
      }
    });
  };

  const _closeModalHandler = event => {
    event.preventDefault();

    const $modal = event.currentTarget.closest(_settings.selector);
    _closeModal($modal);
  };

  const _closeModal = $modal => {
    _removeOpenCurrentState($modal);
    $modal.classList.remove(_settings.openClass);
    _trapFocusToModal();

    if (_settings.$openModals().length > 0) {
      _offsetOpenModals();
      _focusNextElement();

      _settings
        .$openModals()
        .forEach($modal => _removeOpenCurrentState($modal));
      _setOpenCurrentState(_$currentOpenModal());
    } else {
      closeOverlay();
    }
  };

  const _focusNextElement = () => {
    getFocusableElements(_$currentOpenModal())[0].focus();
  };

  const _trapFocusToModal = () => {
    if (_settings.hasOpenModals()) {
      trapFocus(_$trapEls());
      restoreFocus([_$currentOpenModal()]);
    } else {
      restoreFocus(_$trapEls());
    }
  };

  const _closeCurrentModal = () => {
    if (!_settings.hasOpenModals()) return;
    _closeModal(_$currentOpenModal());
  };

  const _keyUpHandler = event => {
    if (event.key === 'Escape') _closeCurrentModal();
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;

    $overlay().removeEventListener('overlay:transitionstart', _closeModals);
    $overlay().removeEventListener('overlay:transitionend', _closeModals);
    document.removeEventListener('keyup', _keyUpHandler);

    _settings.$closeButtons().forEach($button => {
      $button.removeEventListener('click', _closeModalHandler);
    });
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;

    $overlay().addEventListener('overlay:transitionstart', _closeModals);
    $overlay().addEventListener('overlay:transitionend', _closeModals);

    document.body.classList.add(`${_settings.openClass}Modals`);
    _trapFocusToModal();
    _offsetOpenModals();
    _setOpenCurrentState(_$currentOpenModal());

    document.addEventListener('keyup', _keyUpHandler);

    _settings.$closeButtons().forEach($button => {
      $button.addEventListener('click', _closeModalHandler);
    });

    _settings.$modals().classList.add('is-open');
    _settings.$selectors()[0].classList.add('is-open');
    _settings.$selectors()[0].classList.add('is-current');
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
};

// Default module export
export default Modals;
