import { removeMarkup as removeTooltipMarkup } from 'modules/application/tooltips';

export const removeMarkup = $video => {
  const $playIcon = $video.querySelector('.c-video__play');
  const $controls = $video.querySelector('.c-video__controls');
  const $timeline = $video.querySelector('.c-video__progress');

  if ($playIcon) $playIcon.remove();
  if ($timeline) $timeline.remove();
  if ($controls) {
    $controls.removeAttribute('data-tooltip-position');
    removeTooltipMarkup($controls);
  }
  $video.classList.remove('c-video');
};
