const ToggleInductions = () => {
  const _settings = {
    selector: '.js-toggle-inductions',
  };

  const $checkboxes = () => document.querySelectorAll(_settings.selector);

  const _handleChange = $clickedCheckbox => {
    if ($clickedCheckbox.target) $clickedCheckbox = $clickedCheckbox.target;

    let inductionIds = [];

    $checkboxes().forEach($checkbox => {
      if ($checkbox.checked) {
        inductionIds = inductionIds.concat(
          JSON.parse($checkbox.dataset.inductionIds)
        );
      }
    });

    let uniqueInductionids = [...new Set(inductionIds)];

    document.querySelectorAll('.js-induction-site').forEach(el => {
      el.classList.add('u-hide');
    });

    document.querySelectorAll('.js-induction-personal').forEach(el => {
      el.classList.remove('u-hide');
    });

    document
      .querySelector('.js-induction-site-none')
      .classList.remove('u-hide');
    document
      .querySelector('.js-induction-personal-none')
      .classList.remove('u-hide');

    uniqueInductionids.forEach(inductionId => {
      document
        .querySelectorAll(
          `.js-induction-site[data-induction-id="${inductionId}"]`
        )
        .forEach(el => {
          el.classList.remove('u-hide');
          document
            .querySelector('.js-induction-site-none')
            .classList.add('u-hide');
        });

      document
        .querySelectorAll(
          `.js-induction-personal[data-induction-id="${inductionId}"]`
        )
        .forEach(el => {
          el.classList.add('u-hide');
        });
    });

    hideNoPersonalInductions();
  };

  const hideNoPersonalInductions = () => {
    if (
      document.querySelectorAll('.js-induction-personal:not(.u-hide)').length >
      0
    ) {
      document
        .querySelector('.js-induction-personal-none')
        .classList.add('u-hide');
    }
  };

  const destroy = () => {
    if (!$checkboxes()) return;

    $checkboxes().forEach($checkbox => {
      $checkbox.removeEventListener('change', _handleChange);
    });
  };

  const _setupEventListeners = () => {
    $checkboxes().forEach($checkbox => {
      $checkbox.addEventListener('change', _handleChange);
    });
  };

  const reinit = () => {
    destroy();
    init();
  };

  const init = () => {
    if (!$checkboxes()) return;
    _setupEventListeners();

    $checkboxes().forEach($checkbox => {
      if (!$checkbox.closest('.u-hide'))
        _handleChange($checkbox);
    });

    hideNoPersonalInductions();
  };

  return {
    destroy,
    reinit,
    init,
  };
};

export default ToggleInductions;
