import { deleteAnswer } from './delete-answer';
import { addAnswer } from '../components/answer';

export const answerHandler = event => {
  const $target = event.currentTarget;
  const $question = $target.closest('.js-quizQuestion');

  // Backspace
  if (
    (event.code === 'Backspace' || event.which === 8) &&
    $target.value.length === 0
  ) {
    deleteAnswer(event);
  }

  // Return
  if (event.code === 'Enter' || event.which === 13) {
    event.preventDefault();
    addAnswer(null, null, $question);
  }
};
