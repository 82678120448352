let lastTime = 0;

export const preventScrubbing = event => {
  const $video = event.currentTarget;
  const $videoContainer = $video.closest('.js-video');

  if ($videoContainer.dataset.disableProgress !== 'true') return;

  if (Math.abs($video.currentTime - lastTime) > 1) {
    $video.currentTime = lastTime;
  }

  lastTime = $video.currentTime;
};
