/**
 * #THROW ERROR
 * Report errors if we are not in production environment.
 *
 * @param {String}  message Error message to relay back
 * @param {Boolean} log     Whether the error should be logged to the console
 */
export const TE = (message, log = true) => {
  if (process.env.NODE_ENV === 'production') return;

  if (log === true) console.error(message);
  throw new Error(message);
};
