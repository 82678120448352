/*----------------------------------------------------------------------------*\
  #FORMS
\*----------------------------------------------------------------------------*/

// Default module export
export default function(options) {
  // Private: Default settings object
  const _defaults = {
    selector: 'input:not([type="hidden"]), textarea',
    $selectors() {
      return document.querySelectorAll(this.selector);
    },
    exists() {
      return this.$selectors().length > 0;
    },
    valueStateClass: 'has-value',
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // Private: Change state class
  const _changeStateClass = event => {
    const $input = event.currentTarget;
    const valueStateClass = _settings.valueStateClass;

    if ($input.value !== '') {
      $input.classList.add(valueStateClass);
    } else {
      $input.classList.remove(valueStateClass);
    }
  };

  // Private: Toggle display of connected content
  const _toggleConnectedContent = event => {
    // Only perform for inputs with the correct JS hook
    if (!event.currentTarget.classList.contains('js-inputChange')) return;

    // Create a set to hold our checked entries to
    const checked = new Set();

    // Find all input change selectors, and update our set with any checked values
    const $inputChanges = document.querySelectorAll('.js-inputChange');
    $inputChanges.forEach($input => {
      $input.checked ? checked.add($input.id) : checked.delete($input.id);
    });

    // Find all 'connected content'
    const $connectedContents = document.querySelectorAll(
      '.js-connectedContent'
    );
    if (!$connectedContents) return;

    // Find any matches and hide/show accordingly.
    $connectedContents.forEach($content => {
      checked.has($content.dataset.watch)
        ? $content.classList.remove('u-hide')
        : $content.classList.add('u-hide');
    });
  };

  // Public: Destroy module instance
  const destroy = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($input => {
      $input.removeEventListener('change', _changeStateClass);
      $input.removeEventListener('change', _toggleConnectedContent);
    });
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.exists()) return;

    _settings.$selectors().forEach($input => {
      $input.addEventListener('change', _changeStateClass);
      $input.addEventListener('change', _toggleConnectedContent);
    });
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
}
