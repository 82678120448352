/*----------------------------------------------------------------------------*\
  #Button background change
\*----------------------------------------------------------------------------*/

// Imports
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

let animation;

const ButtonBgChange = () => {
  gsap.registerPlugin(ScrollTrigger);

  // Public: Destroy module instance
  const destroy = () => {
    animation && animation.kill();
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    const buttonBg = document.querySelector('.js-button-bg');
    const addClass = () => buttonBg.classList.add('is-dark');
    const removeClass = () => buttonBg.classList.remove('is-dark');

    if (!buttonBg) return;

    animation = ScrollTrigger.create({
      trigger: '.js-trigger-bg-color',
      start: 'top bottom',
      end: 'top 0',
      onEnter: addClass,
      onLeave: removeClass,
      onEnterBack: addClass,
      onLeaveBack: removeClass,
    });
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
};

// Default module export
export default ButtonBgChange;
