export const preventReturn = event => {
  const $target = event.currentTarget;
  const $question = $target.closest('.js-quizQuestion');

  // Return
  if (event.code === 'Enter' || event.which === 13) {
    event.preventDefault();
    const $nextFocusableEl = $question.elements[1];
    $nextFocusableEl.focus();
  }
};
