import { createEl } from 'util/create-el';

export const createNav = $targetNode => {
  const hasVideo = $targetNode.dataset.video;
  const resetText = hasVideo ? 'Rewatch Video' : 'Restart Quiz';

  const $nav = createEl('nav', {
    className: 'js-quizNav',
  });

  const $ul = createEl('ul', {
    className: 'o-list  o-list--inline  [ u-justifyEnd  u-alignCenter ]',
  });

  const $liConfirm = createEl('li', {
    className: 'o-list__item',
  });

  const $continueButton = createEl('button', {
    textContent: 'Continue',
    type: 'button',
    className: 'c-button  js-quizContinue',
    disabled: true,
  });

  const $liRewatch = createEl('li', {
    className: 'o-list__item',
  });

  const $rewatchButton = createEl('button', {
    textContent: resetText,
    type: 'button',
    className: 'c-textLink  js-watchVideo',
  });

  const $liReset = createEl('li', { className: 'o-list__item  u-hide' });
  const $resetButton = createEl('button', {
    textContent: resetText,
    type: 'button',
    className: 'c-button  js-resetQuiz',
  });

  $liConfirm.appendChild($continueButton);
  $liRewatch.appendChild($rewatchButton);
  $liReset.appendChild($resetButton);
  $ul.appendChild($liConfirm);
  if (hasVideo) $ul.appendChild($liRewatch);
  $ul.appendChild($liReset);
  $nav.appendChild($ul);

  $targetNode.appendChild($nav);

  const eventData = {
    detail: {},
  };

  const event = new CustomEvent('quiz:reset', eventData);

  $resetButton.addEventListener('click', () =>
    $targetNode.dispatchEvent(event)
  );
};
