import UploadModal from 'modules/application/upload-modal';

export default function(options) {
  // Private: Default settings object
  const _defaults = {
    formSelector: '.js-videoUploadForm',
    $formSelector() {
      return document.querySelector(this.formSelector);
    },
    formExists() {
      return this.$formSelector();
    },
  };

  // Private: Merge passed in object with defaults
  const _settings = {
    ..._defaults,
    ...options,
  };

  // START Does this need to go in settings?
  const isVideoUploadInputPresent = () => {
    const videoInput = document.querySelector('.js-kind-video');

    return !videoInput.classList.contains('u-hide');
  }
  // END Does this need to go in settings?

  const submitHandler = (e) => {
    const selectedFileName = document.querySelector('.c-uploadFiles__item').innerHTML;
    const modalFileName = document.querySelector('.c-modal__file');

    if (isVideoUploadInputPresent()) {
      UploadModal().init();
      modalFileName.innerHTML = selectedFileName;
    }
  }

  // Public: Destroy module instance
  const destroy = () => {
    if (_settings.formExists()) {
      _settings.$formSelector().removeEventListener('submit', submitHandler);
    }
  };

  // Public: Destroy module instance and run initialise again
  const reinit = () => {
    destroy();
    init();
  };

  // Public: Initialise module
  const init = () => {
    if (!_settings.formExists()) { return;}

    _settings.$formSelector().addEventListener('submit', submitHandler);
  };

  // Return public methods
  return {
    destroy,
    reinit,
    init,
  };
}
